var wdot = wdot || {};
wdot.Widgets = function () {
	var currentKey = 1,
	_log = false,
	initializedCategories = false,
	widgetPropertiesViewModel = function () {
		var properties = ko.observable(),
		id = ko.observable(),
		key = ko.observable(currentKey),
		zone = ko.observable(),
		codename = ko.observable(),
		editAjaxPostData = {},
		saveWidget = function () {
			if(_log) console.log('saveWidget()');
			var s;
			var success = false;
			var widgetBeingEdited = $("#widget-container [data-isInEditMode='true']");
			if(_log) console.log('widgetBeingEdited:', widgetBeingEdited);
			if (wdot.Widgets.widgetPropertiesViewModel.saveWidgetFunction != undefined) {
				s = wdot.Widgets.widgetPropertiesViewModel.saveWidgetFunction(widgetBeingEdited.attr("data-key"));
				success = s.success;
				if (success) {
					if (widgetBeingEdited.attr("data-isNew") == 'true') {
						widgetAsJson({ id: id(), zone: zone(), properties: properties(), key: key(), isNew: true });
						key(key() + 1);
						currentKey++;
					} else {
						var widget = wdot.Widgets.contentCreationViewModel.getWidgetByKey(widgetBeingEdited.attr("data-key"));
						widgetAsJson({ id: widget.id, zone: widget.zone, properties: properties(), key: widget.key, isNew: false });
					}
				}
			}
			if (success) {
				if ($(".currentTemplate").length > 0) {
					ko.applyBindings(widgetAsJson().properties, $(".currentTemplate")[0]);
					$(".currentTemplate").removeClass("currentTemplate");
				}
				isSaving = true;
				Wdot.ModalWindow.close();
				widgetAsJson().isNew = false;
				$(widgetBeingEdited).attr("data-isInEditMode", false);
				$(widgetBeingEdited).attr("data-isNew", false);
				s.item.append("<a href='javascript:void(0)' class='widget-remove'>Remove</a>");
				s.item.append("<a href='javascript:void(0)' class='widget-edit' data-ajaxPostData='" + editAjaxPostData + "'>Edit</a>");
				//contentCreationViewModel.removeMsCategories();
				initializedCategories = false;
			}
			else {
				alert("Error saving widget.<br/>" + (s != undefined && s.message != undefined) ? s.message : '');
			}
		},
		cancelWidget = function () {
			Wdot.ModalWindow.close();
		},
		isSaving = false,
		saveWidgetFunction,
		widgetAsJson = ko.observable();
		$("#dialog").on("dialogclose", function (event, ui) {
			if (!isSaving) {
				var widgetBeingEdited = $("#widget-container [data-isInEditMode='true']");
				if ($(widgetBeingEdited).attr("data-isNew") == 'true') {
					$(widgetBeingEdited).remove();
				} else {
					$(widgetBeingEdited).attr("data-isInEditMode", false);
				}
			}
			isSaving = false;
			$("#dialog").html('');
		});
		return {
			id: id,
			key: key,
			zone: zone,
			codename: codename,
			saveWidget: saveWidget,
			properties: properties,
			cancelWidget: cancelWidget,
			widgetAsJson: widgetAsJson,
			saveWidgetFunction: saveWidgetFunction,
			editAjaxPostData: editAjaxPostData
		};
	} (),
	media = function () {
		var id = ko.observable(),
		fileLocation = ko.observable(),
		title = ko.observable(),
		summary = ko.observable(),
        photocredit = ko.observable(),
		actionLink = ko.observable(),
		format = ko.observable(),
		style = ko.observable(),
		alignment = ko.observable(),
		mediaClass = ko.observable(),
		isInEditMode = ko.observable(),
		fileType = ko.observable(),
		youtubeid = ko.observable(),
		youtubethumbnail = ko.observable(),
		youtubeurl = ko.observable();
		return {
			id: id,
			fileLocation: fileLocation,
			title: title,
			summary: summary,
            photocredit: photocredit,
			actionLink: actionLink,
			format: format,
			style: style,
			alignment: alignment,
			mediaClass: mediaClass,
			fileType: fileType,
			youtubeid: youtubeid,
			youtubethumbnail: youtubethumbnail,
			youtubeurl: youtubeurl,
			isInEditMode: isInEditMode
		};
	},
	youTubeVideo = function () {
		var youTubeId = ko.observable(),
		title = ko.observable(),
		summary = ko.observable(),
		thumbnailUrl = ko.observable(),
		isInEditMode = ko.observable();
		return {
			youTubeId: youTubeId,
			title: title,
			summary: summary,
			isInEditMode: isInEditMode,
			thumbnailUrl: thumbnailUrl
		};
	},
	contentType = function () {
		var name = ko.observable(),
		id = ko.observable();
		return {
			name: name,
			id: id
		};
	},
	listItem = function () {
		var name = '',
		id = 0,
		displayOrder = 0;
		return {
			name: name,
			id: id,
			displayOrder: displayOrder
		};
	},
	attributeFilter = function () {
		var id = ko.observable(),
		name = ko.dependentObservable(function () {
			if (id() != undefined) {
				return wdot.Widgets.listingCreationViewModel.getAttributeNameById(id());
			}
			return '';
		}),
		operator = ko.observable(),
		value = ko.observable(),
		filter = ko.dependentObservable(function () {
			return name() + ' ' + operator() + ' ' + value();
		}),
		index = ko.observable();
		return {
			name: name,
			id: id,
			operator: operator,
			value: value,
			filter: filter,
			index: index
		};
	},
	processListProperty = function (list) {
		if (list.length == undefined) {
			return [list];
		}
		return list;
	},
	mediaLibraryViewModel = function () {
		var self = this,
			mediaList = ko.observableArray(),
			src = ko.observable(),
			alt = ko.observable(),
			title = ko.observable(),
			id = ko.observable(),
			mediaImage = ko.computed(function () {
				return { src: src(), alt: alt(), title: title() };
			}).extend({ throttle: 500 }),
			loadMediaSelector = function () {

			    if ($("[name=TemplateOverride]").val() === fallcampaigntemplate) {
			        window.open('/admin/medialibrary?ispopup=true&canSelectAlbum=true', 'mywindow', 'location=0,status=1,scrollbars=1,resizable=1,width=800,height=600');
			    } else {
			        window.open('/admin/medialibrary?ispopup=true', 'mywindow', 'location=0,status=1,scrollbars=1,resizable=1,width=800,height=600');
			    }
			    
			},
			hasYouTubeVideo = function () {
				return ko.utils.arrayFirst(mediaList(), function (item) {
					return item.fileType() == "Video";
				}) != null;
			},
			youtubetemplate = "~/Areas/Admin/Views/Shared/Widgets/_WidgetMediaGalleryDisplay.YouTubeDisplay.cshtml",
            youtube360template = "~/Areas/Admin/Views/Shared/Widgets/_WidgetMediaGalleryDisplay.YouTubeDisplay360.cshtml",
	        youtubeFamilyHeaderVideoTemplate = "~/Areas/Admin/Views/Shared/Widgets/FamilyTrips/_WidgetMediaGalleryDisplay.YouTubeDisplayFamilyTripGenerationHeader.cshtml",
			youtubeinspirationtemplate = "~/Areas/Admin/Views/Shared/Widgets/_WidgetMediaGalleryDisplay.InspirationHeaderYouTube.cshtml",
			youtubetemplateplaylist = "~/Areas/Admin/Views/Shared/Widgets/_WidgetMediaGalleryDisplay.PlaylistFeature.cshtml",
            fallcampaigntemplate = "~/Areas/Admin/Views/Shared/Widgets/_WidgetMediaGalleryDisplay.FallCampaignWeeklyImages.cshtml",
			industyHomepage = "~/Areas/Admin/Views/Shared/Widgets/_WidgetMediaGalleryDisplay.IndustryHomepageYouTube.cshtml",
			citiesRegionsFeaturedVideo = "~/Areas/Admin/Views/Shared/Widgets/CitiesAndRegions/_WidgetMediaGalleryDisplay.CitiesRegionsFeaturedVideo.cshtml",

            addMedia = function (actionLink, id, fileLocation, title, summary, photocredit, isInEditMode, format, style, alignment, mediaClass, fileType, youtubeid, youtubethumbnail, youtubeurl) {

				if ($("[name=TemplateOverride]").val() == youtubetemplate || $("[name=TemplateOverride]").val() == youtube360template || $("[name=TemplateOverride]").val() == youtubeinspirationtemplate || $("[name=TemplateOverride]").val() == youtubeFamilyHeaderVideoTemplate || $("[name=TemplateOverride]").val() == industyHomepage || $("[name=TemplateOverride]").val() == citiesRegionsFeaturedVideo) {
					if (wdot.Widgets.mediaLibraryViewModel.mediaList().length >= 1 || fileType != "Video") {
						alert("The YouTube Display template can only have 1 YouTube video.");
						return;
					}
				} else if ($("[name=TemplateOverride]").val() != youtubetemplate && $("[name=TemplateOverride]").val() != youtube360template && $("[name=TemplateOverride]").val() != youtubeFamilyHeaderVideoTemplate && $("[name=TemplateOverride]").val() != youtubetemplateplaylist && $("[name=TemplateOverride]").val() != youtubeinspirationtemplate && $("[name=TemplateOverride]").val() != industyHomepage && fileType == "Video" && $("[name=TemplateOverride]").val() != citiesRegionsFeaturedVideo && fileType == "Video") {
					alert("You can only add YouTube videos to the YouTube Display Media Gallery Template");
					return;
				}

                //Prevent an empty media item from appearing in the list and causing an error.
                else if ($("[name=TemplateOverride]").val() === fallcampaigntemplate) {
                    return;
                }

				var m = new media();
				m.actionLink(actionLink);
				m.format(format);
				m.style(style);
				m.alignment(alignment);
                m.mediaClass(mediaClass);
				m.id(id);
				m.fileLocation(fileLocation);
				m.title(wdot.Utils.HtmlDecode(title));
				m.summary(wdot.Utils.HtmlDecode(summary));
				m.photocredit(wdot.Utils.HtmlDecode(photocredit));
				m.fileType(fileType);
				m.youtubeid(youtubeid);
				m.youtubethumbnail(youtubethumbnail);
				m.youtubeurl(youtubeurl);
				m.isInEditMode(isInEditMode);
				mediaList.push(m);
			},
			loadMedia = function (ml) {
				$(ml.mediaItem).each(function () {
					var m = new media();
					m.actionLink(this.actionLink);
					m.format(this.format);
					m.style(this.style);
					m.alignment(this.alignment);
					m.mediaClass(this.mediaClass);
					m.id(this.id);
					if (this.fileType == "Video") {
						m.fileLocation(this.youtubethumbnail);
					} else {
						m.fileLocation(this.fileLocation);
					}
					m.title(wdot.Utils.HtmlDecode(this.title));
					m.summary(wdot.Utils.HtmlDecode(this.summary));
					m.photocredit(wdot.Utils.HtmlDecode(this.photocredit));
					m.fileType(this.fileType);
					m.youtubeid(this.youtubeid);
					m.youtubethumbnail(this.youtubethumbnail);
					m.youtubeurl(this.youtubeurl);
					m.isInEditMode(false);
					mediaList.push(m);
				});
			},
            loadAlbumItems = function (id) {
                jQuery.ajax({
                    url: '/admin/medialibrary/GetAlbumItems',
                    type: "POST",
                    data: {
                        id: id
                    },
                    success: function (data) {
                        $(data).each(function () {
                            var m = new media();
                            m.actionLink(this.MediaFile.ActionLink);
                            m.format(this.MediaFile.Format);
                            m.style(this.MediaFile.Style);
                            m.alignment(this.MediaFile.Alignment);
                            m.mediaClass(this.MediaFile.MediaClass);
                            m.id(this.MediaFile.Id);
                            if (this.MediaFile.FileType == "Video") {
                                m.fileLocation(this.MediaFile.Youtubethumbnail);
                            } else {
                                m.fileLocation(this.MediaFile.FileLocation);
                            }
                            m.title(wdot.Utils.HtmlDecode(this.MediaFile.Title));
                            m.summary(wdot.Utils.HtmlDecode(this.MediaFile.Summary));
                            m.photocredit(wdot.Utils.HtmlDecode(this.MediaFile.photocredit));
                            m.fileType(this.MediaFile.FileType);
                            m.youtubeid(this.MediaFile.Youtubeid);
                            m.youtubethumbnail(this.MediaFile.Youtubethumbnail);
                            m.youtubeurl(this.MediaFile.Youtubeurl);
                            m.isInEditMode(false);
                            mediaList.push(m);
                        });
                    }
                });
	        },
	        edit = function (i) {
				i.isInEditMode(true);
			},
			save = function (i) {
				i.isInEditMode(false);
			},
			resetMediaLibraryViewModel = function () {
				mediaList.removeAll();
			},
			deleteItem = function (i) {
				mediaList.remove(i);

			};
		mediaImage.subscribe(function () {
			if (src() != undefined && alt() != undefined && title != undefined) {
				$('#src').attr('value', src());
				$('#alt').attr('value', alt());
				$('#title').attr('value', title());
				wdot.Widgets.mediaLibraryViewModel.addMedia('', id(), src(), '', '', true, '', '');
			}
		});
		return {
			mediaList: mediaList,
			addMedia: addMedia,
			loadMedia: loadMedia,
			edit: edit,
			save: save,
			deleteItem: deleteItem,
			resetMediaLibraryViewModel: resetMediaLibraryViewModel,
			loadMediaSelector: loadMediaSelector,
			src: src,
			alt: alt,
			title: title,
			mediaImage: mediaImage,
			id: id,
			hasYouTubeVideo: hasYouTubeVideo,
			loadAlbumItems: loadAlbumItems
		};
	} (),
	youTubeViewModel = function () {
		var videoList = ko.observableArray(),
			src = ko.observable(),
			title = ko.observable(),
			youTubeId = ko.observable(),
			thumbnailUrl = ko.observable(),
			video = ko.computed(function () {
				return { src: src(), title: title() };
			}).extend({ throttle: 500 }),
			retrieveYouTubeVideo = function (event) {
				var youTubeId = $("input[type=text][name=youTubeId]").val();
				if (!isVideoAlreadyAdded(youTubeId)) {
					wdot.YouTube.retrieveYouTubeVideo(youTubeId);
				}
				else {
					alert("Video has already been added.");
				}
				$("input[type=text][name=youTubeId]").val("");
			},
			addYouTubeVideo = function (youTubeId, thumbnailUrl, title, summary, isInEditMode) {
				if (!isVideoAlreadyAdded(youTubeId)) {
					var m = new youTubeVideo();
					m.youTubeId(youTubeId);
					m.title(wdot.Utils.HtmlDecode(title));
					m.summary(wdot.Utils.HtmlDecode(summary));
					m.thumbnailUrl(thumbnailUrl);
					m.isInEditMode(isInEditMode);
					videoList.push(m);
				}
				else {
					alert("Video has already been added.");
				}
			},
			isVideoAlreadyAdded = function (youTubeId) {
				var unwrappedVideoList = ko.toJS(videoList);
				var video = ko.utils.arrayFirst(unwrappedVideoList, function (item) {
					return item.youTubeId == youTubeId;
				});
				return video != null;
			},
			loadYouTubeVideos = function (videos) {
				$(videos.youTubeVideo).each(function () {
					var m = new youTubeVideo();
					m.youTubeId(this.youTubeId);
					m.thumbnailUrl(this.thumbnailUrl);
					m.title(wdot.Utils.HtmlDecode(this.title));
					m.summary(wdot.Utils.HtmlDecode(this.summary));
					m.isInEditMode(false);
					videoList.push(m);
				});
			},
			edit = function (video) {
				video.isInEditMode(true);
			},
			save = function (video) {
				video.isInEditMode(false);
			},
			removeAllVideos = function () {
				videoList.removeAll();
			},
			deleteItem = function (video) {
				videoList.remove(video);
			};
		video.subscribe(function () {
			if (src() != undefined && title != undefined) {
				$('#src').attr('value', src());
				$('#title').attr('value', title());
				wdot.Widgets.mediaLibraryViewModel.addMedia('', id(), src(), '', '', true, '', '');
			}
		});
		return {
			videoList: videoList,
			addYouTubeVideo: addYouTubeVideo,
			loadYouTubeVideos: loadYouTubeVideos,
			edit: edit,
			save: save,
			deleteItem: deleteItem,
			removeAllVideos: removeAllVideos,
			src: src,
			title: title,
			video: video,
			youTubeId: youTubeId,
			retrieveYouTubeVideo: retrieveYouTubeVideo,
			thumbnailUrl: thumbnailUrl
		};
	} (),
	listingCreationViewModel = function () {
		//if(_log) console.log('Widgets.listingCreationViewModel()');
		var contenttype = ko.observable(),
		contentTypes = ko.observableArray(),
		displaytype = ko.observable(),
		displaytypeForDisplay = function (displayTypeId) {
			if (displayTypes != undefined) {
				var dt = ko.utils.arrayFirst(displayTypes, function (item) {
					return item.id == displayTypeId;
				});
				if (dt != null) {
					return dt.name;
				}
			}
			return displayTypeId;
		},
        displaytypeForRecommended = function(displayTypeId) {
            if (recommendedDisplayTypes != undefined) {
                var dt = ko.utils.arrayFirst(recommendedDisplayTypes, function (item) {
                    return item.id == displayTypeId;
                });
                if (dt != null) {
                    return dt.name;
                }
            }
            return displayTypeId;
        },
		displaytypeForTrending = function (displayTypeId) {
		    if (trendingDisplayTypes != undefined) {
		        var dt = ko.utils.arrayFirst(trendingDisplayTypes, function (item) {
		            return item.id == displayTypeId;
		        });
		        if (dt != null) {
		            return dt.name;
		        }
		    }
		    return displayTypeId;
		},
		categoriesForDisplay = function (categoryTypeIds) {
			if(_log) console.log('categoriesForDisplay.categoryTypeIds:', categoryTypeIds);
			if (allCategories() != undefined && allCategories().length > 0 && categoryTypeIds != null) {
				if(typeof categoryTypeIds != 'string') {
					categoryTypeIds = categoryTypeIds.join(',');
					//if(_log) console.log('categoriesForDisplay.categoryTypeIds updated*:', categoryTypeIds);
				}
				var splitCategoryTypeIds = categoryTypeIds.split(',');
				var categoryNameArray = [];
				ko.utils.arrayForEach(splitCategoryTypeIds, function (catId) {
					var cn = recursiveCategoryScan(allCategories(), catId);

					if (cn != null) {
						categoryNameArray.push(cn);
					}
				});

				if (categoryNameArray.length > 0) {
					return categoryNameArray.join(', ');
				}
			}
			return categoryTypeIds;
		},
		recursiveCategoryScan = function (cats, catId) {
			for (var i = 0; i < cats.length; i++) {
				if (cats[i].Id == catId) {
					return cats[i].Name;
				}

				if (cats[i].Children != undefined && cats[i].Children.length > 0) {
					return recursiveCategoryScan(cats[i].Children, catId);
				}
			}
		},
		contentTypeForDisplay = function (contentTypeId) {
			if (contentTypes() != undefined) {
				var ct = ko.utils.arrayFirst(contentTypes(), function (item) {
					return item.id == contentTypeId;
				});
				if (ct != null) {
					return ct.name;
				}
			}
			return contentTypeId;
		},
		displayTypes = [
			{ id: 1, name: 'Summary' },
			{ id: 2, name: 'Directory' },
			{ id: 3, name: 'Summary Without Images' },
			{ id: 4, name: 'Playlist Slider' },
			{ id: 5, name: 'Playlist Slider Feature' },
			{ id: 6, name: 'Playlist Homepage' }, 
			{ id: 7, name: 'Playlist Homepage Feature' },
			{ id: 8, name: 'Collage'},
			{ id: 9, name: 'Region Place Preview' },
			{ id: 10, name: 'Cities and Regions Featured Listing' },
            { id: 11, name: 'Category Summary Collage' },
            { id: 12, name: 'Ad Collage' },
            { id: 15, name: 'Category Featured Directory' },
            { id: 16, name: 'Must Read' },
            { id: 17, name: 'Media Gallery Scroller' },
            { id: 18, name: 'Homepage Six Picks Scroller' },
            { id: 19, name: 'Sweepstakes Related Articles' },
            { id: 20, name: 'Industry Define Fun' },
            { id: 21, name: 'PPC Landing Page Directory' },
            { id: 22, name: 'Selfie Stand Location Directory' },
            { id: 23, name: 'Articles Landing Page Directory' },
            { id: 24, name: 'Email Alert Image' },
            { id: 25, name: 'Region Categories' },
		    { id: 26, name: 'Featured Stories' },
            { id: 27, name: 'Category Preview' },
            { id: 28, name: 'Featured Video' },
            { id: 29, name: 'Inspiration Categories' },
            { id: 30, name: 'Dynamic Deals' },
			{ id: 31, name: 'Winter Campaign Deals' },
			{ id: 32, name: 'Directory Weekend Events' },
			{ id: 33, name: 'Directory Accessibility' },
			{ id: 34, name: 'Article Slider 2023' },
			{ id: 35, name: 'Directory Article 2023' },
			{ id: 36, name: 'Directory 2023' },
			{ id: 37, name: 'Category Slider 2023' },
			{ id: 38, name: 'Featured Listing' }
        ],
        recommendedDisplayTypes = [
            { id: 13, name: 'Full Width' },
            { id: 14, name: 'Half Width' }
        ],
        trendingDisplayTypes = [
            { id: 13, name: 'Full Width' },
            { id: 14, name: 'Half Width' }
        ],
		editCategories = function () {
			if(_log) console.log('editCategories()');
			if(_log) console.log('editCategories().selectedCategories()', selectedCategories());

			$("#listing-main-view").addClass('hidden');
			$("#listing-category-edit").removeClass('hidden');
            $("#widget-buttons").addClass('hidden');

			if($('.js-ms-categories').length > 0 && !initializedCategories) {
				if(_log) console.log('FOUND js-ms-categories');
				setTimeout(() => {
					//https://www.jqueryscript.net/form/sortable-multi-select.html#google_vignette
					$('.multiselectsortable').multiselect_sortable({
						selectable:{
							title:'Unselected',
							sortable: false,
						},
						selection :{
							title:'Selected Categories',
							sortable: true
						},
						reverse:true,
						selectedOrder: selectedCategories()
					});
					initializedCategories = true;
					if(_log) console.log('instantiate: js-ms-categories');
				}, 100);
			}

            if (selectedCategories().length > 0) {
				checkForSelectedCatsAsString();
                // if (typeof selectedCategories()[0] === 'string') {
                //     // If the user already saved the widget and have now reopened it, something stupid happens..
                //     // "selectedCategories" is usually an array of integers, but this time, it's an array of strings (representing integer IDs).
                //     // If we can change the array back to integers then things will work again after the user clicked Save.
                //     var newIntArray = [];

                //     selectedCategories().forEach(function (catId) {
                //         if (parseInt(catId) !== 'NaN') {
                //             newIntArray.push(parseInt(catId));
                //         }
                //     });

                //     // Update selected categories
                //     selectedCategories(newIntArray);
                // };

                // Select unselected categories
				//ORIG vv
				// selectedCategories().forEach(function (catId) {
                //     var categoryChk = $('input[value="' + catId + '"][name!="CategoryListingModel.PrimaryCategory"]');
                //     if (!categoryChk.is(':checked')) {
                //         categoryChk.prop('checked', 'checked');
                //     }
                // });
            } 
		},
		checkForSelectedCatsAsString = function () {
			if(_log) console.log('checkForSelectedCatsAsString.selectedCategories-1:', selectedCategories());
			//if(_log) console.log('checkForSelectedCatsAsString.selectedCategories()[0]:', selectedCategories()[0]);
			//if(_log) console.log('checkForSelectedCatsAsString.typeof selectedCategories()[0]:', typeof selectedCategories()[0]);
			if (selectedCategories() != null && selectedCategories()[0] != null && typeof selectedCategories()[0] === 'string') {
				// If the user already saved the widget and have now reopened it, something stupid happens..
				// "selectedCategories" is usually an array of integers, but this time, it's an array of strings (representing integer IDs).
				// If we can change the array back to integers then things will work again after the user clicked Save.
				var newIntArray = [];

				selectedCategories().forEach(function (catId) {
					if(_log) console.log('checkForSelectedCatsAsString.selectedCategories.forEach.catId:', catId);
					if (parseInt(catId) !== 'NaN') {
						newIntArray.push(parseInt(catId));
					}
				});

				// Update selected categories
				selectedCategories(newIntArray);
				if(_log) console.log('checkForSelectedCatsAsString.selectedCategories-2:', selectedCategories());
			};
			if(_log) console.log('checkForSelectedCatsAsString.selectedCategories-3:', selectedCategories());
		},
		cities = ko.observableArray(),
		counties = ko.observableArray(),
		regions = ko.observableArray(),
		categories = ko.observableArray(),
		allCategories = ko.observableArray(),
		selectedCategories = ko.observableArray(),
		throttledSelectedCategories = ko.computed(selectedCategories).extend({ throttle: 500 }),
		categoriesAsString = ko.dependentObservable(function () {
			//if(_log) console.log('categoriesAsString:', categoriesAsString);
			if(_log) console.log('categoriesAsString.selectedCategories():', selectedCategories());
			checkForSelectedCatsAsString();

			if(_log) console.log('categoriesAsString.categories():', categories());
			if (categories() != undefined && categories().length > 0) {

				var catString = '';
				selectedCategories().forEach(function (catId) {
                    if(_log) console.log('categoriesAsString.forEach.catId:', catId);
					var foundCat = categoriesFlat.find(({ id }) => id == catId);
					//if(_log) console.log('foundCat:', foundCat);
					if(foundCat) {
						if(catString != '') catString += ', ';
						catString += foundCat.name;
					}
                });
				if(_log) console.log('categoriesAsString.catString:', catString);
				return catString;

				//ORIG VV
				// var t = $(selectedCategories()).map(function () {
				// 	return $("#listing-category-edit input[type='checkbox'][value='" + this + "']").siblings("span").text();
				// }).get().join(',');
				// return t;

			}
			return '';
		}),
		searchChildCategories = function (children, id) {
			//if(_log) console.log('searchChildCategories:', children);
			var name = '';
			$(children).each(function () {
				if (this.id == id) {
					name = this.name;
				}

				if (name == '') {
					name = searchChildCategories(this.children, id);
				}
			});
			return name;
		},
		isCategorySelected = function (id) {
			return $.inArray(id.toString(), selectedCategories()) > -1;
		},
		tags = ko.observableArray(),
		addTag = function () {
			var tagValue = $("#listing-tags-autocomplete").val();
			if (tagValue != undefined && tagValue != '') {
				if (!containsTag(tagValue)) {
					tags.push({ name: tagValue });
				}
			}
		},
		removeTag = function (tag) {
			tags.remove(tag);
		},
		addCity = function () {
			var value = $("#cities-autocomplete");
			if (value != undefined && value != '' && value.attr("data-itemid") != 0) {
				if (!cityIsAdded(value.attr("data-itemid"))) {
					var i = new listItem();
					i.id = value.attr("data-itemid");
					i.name = value.val();
					cities.push(i);
				} else {
					alert('City is already added.');
				}
			}
			value.val('');
			value.attr("data-itemid", 0);
		},
		removeCity = function (city) {
			cities.remove(city);
		},
		cityIsAdded = function (id) {
			return $.inArray(id.toString(), $(cities()).map(function () { return this.id || 0; })) > 0;
		},
		addCounty = function () {
			var value = $("#counties-autocomplete");
			if (value != undefined && value != '' && value.attr("data-itemid") != 0) {
				if (!countyIsAdded(value.attr("data-itemid"))) {
					var i = new listItem();
					i.id = value.attr("data-itemid");
					i.name = value.val();
					counties.push(i);
				} else {
					alert('County is already added.');
				}
			}
			value.val('');
			value.attr("data-itemid", 0);

		},
		removeCounty = function (county) {
			counties.remove(county);
		},
		countyIsAdded = function (id) {
			return $.inArray(id.toString(), $(counties()).map(function () { return this.id || 0; })) > 0;
		},
		addRegion = function() {
			var value = $("#regions-autocomplete");
			if (value != undefined && value != '' && value.attr("data-itemid") != 0) {
				if (!regionIsAdded(value.attr("data-itemid"))) {
					var i = new listItem();
					i.id = value.attr("data-itemid");
					i.name = value.val();
					regions.push(i);
				} else {
					alert('Region is already added.');
				}
			}
			value.val('');
			value.attr("data-itemid", 0);
		},
		removeRegion = function(region) {
			regions.remove(region);
		},
		regionIsAdded = function(id) {
			return $.inArray(id.toString(), $(regions()).map(function () { return this.id || 0; })) > 0;
		},
		includeItems = ko.observableArray(),
		excludeItems = ko.observableArray(),
		addIncludeItem = function () {
			var value = $("#include-items-autocomplete");
			if (value != undefined && value != '' && value.attr("data-itemid") != 0) {
				if (!itemIsExcluded(value.attr("data-itemid"))) {
					if (!itemIsIncluded(value.attr("data-itemid"))) {
						var i = new listItem();
						i.id = value.attr("data-itemid");
						i.name = value.val();
						includeItems.push(i);
					}
				} else {
					alert('Item is already excluded.');
				}
			}
			value.val('');
			value.attr("data-itemid", 0);
		},
		removeIncludeItem = function (item) {
			includeItems.remove(item);
		},
		addExcludeItem = function () {
			var value = $("#exclude-items-autocomplete");
			if (value != undefined && value != '' && value.attr("data-itemid") != 0) {
				if (!itemIsIncluded(value.attr("data-itemid"))) {
					if (!itemIsExcluded(value.attr("data-itemid"))) {
						var i = new listItem();
						i.id = value.attr("data-itemid");
						i.name = value.val();
						excludeItems.push(i);
					}
				} else {
					alert('Item is already included.');
				}
			}
			value.val('');
			value.attr("data-itemid", 0);
		},
		removeExcludeItem = function (item) {
			excludeItems.remove(item);
		},
		onlyFeaturedListings = ko.observable(false),
		numResults = ko.observable(),
		numResultsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'All'],
		saveCategories = function () {
			//if(_log) console.log('saveCategories()');
			if(_log) console.log('saveCategories.selectedCategories-1:', selectedCategories());

			// if(_log) console.log('saveCategories.$(.js-ms-categories):', $('.js-ms-categories').length);
			// if(_log) console.log('saveCategories.$(.js-ms-categories):', $('.js-ms-categories').width());

			var msCategories = null;

			$('.js-ms-categories').each(function(index, item) {
				console.group('item-' + index);
				console.group('item.length:', item.length);
				console.log(item);
				if(item.length > 0) msCategories = $(item).val();
				console.groupEnd();
			});

			if(msCategories != null) {
				var uniqueCategories = [...new Set(msCategories)];
				if(_log) console.log('saveCategories.uniqueCategories:', uniqueCategories);
				msCategories = uniqueCategories;
			}

			//var msCategories = $('.js-ms-categories').val();
			if(_log) console.log('saveCategories.msCategories:', msCategories);


			$("#listing-category-edit").addClass('hidden');
			$("#listing-main-view").removeClass('hidden');
            $("#widget-buttons").removeClass('hidden');


			selectedCategories(msCategories);

			if(_log) console.log('saveCategories.selectedCategories-2:', selectedCategories());
			checkForSelectedCatsAsString();

            // Remove unchecked categories from the list
			
			//orig vvvv
            // selectedCategories().forEach(function (catId) {
			// 	if(_log) console.log('NEED TO CHANGE THIS VVVV');
            //     var categoryChk = $('input[value="' + catId + '"][name!="CategoryListingModel.PrimaryCategory"]');
            //     if (!categoryChk.is(':checked')) {
            //         // Remove from list.
            //         selectedCategories().splice($.inArray(catId, selectedCategories()), 1);
            //     }
            // });

            // Update categoriesAsString value
            categoriesAsString.notifySubscribers(categoriesAsString());
		},
		loadContentAutocomplete = function (selector) {
			var lastXhr = '',
			noMatches,
			itemCounter = 0,
			itemCount,
			itemWasAdded;
			$(selector).autocomplete({
				source: function (request, response) {
					if (contenttype() != undefined) {
						lastXhr = Wdot.Ajax.prototype.Get("/admin/contentpage/contenttypeautocomplete/" + contenttype(), request, function (data, status, xhr) {
							if (!data || data.length == 0) {
								noMatches = true;
								data.push({});
							}
							itemCount = data.length;
							if (xhr === lastXhr) {
								response(data);
							}
						});
					}
				},
				focus: function (event, ui) {
					if (ui.item.value != undefined && $(ui.item).val() == 'All matches have been assigned or there are no matches.')
						$(ui.item).disable();

					$(selector).val(ui.item.Name);
					return false;
				},
				select: function (event, ui) {
					if (ui.item.value != undefined && $(ui.item).val() == 'All matches have been assigned or there are no matches.')
						return false;

					$(selector).val(ui.item.Name);
					$(selector).attr("data-itemid", ui.item.Id);
					return false;
				},
				minLength: 1
			}).data("ui-autocomplete")._renderItem = function (ul, item) {
				if (!noMatches) {
					if (!itemIsExcluded(item.Id) && !itemIsIncluded(item.Id)) {
						itemWasAdded = true;
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append("<a data-itemid='" + item.Id + "'>" + item.Name + "</a>")
						.appendTo(ul);
					}

					itemCounter++;

					if (itemCounter == itemCount && !itemWasAdded) {
						item.value = 'All matches have been assigned or there are no matches.';
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
					}

					return '';
				}
				noMatches = false;
				item.value = 'All matches have been assigned or there are no matches.';
				return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
			};
			$(selector).blur(function (event) {
				var autocomplete = $(this).data("ui-autocomplete");
				var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val()), "i");
				autocomplete.widget().children(".ui-menu-item").each(function () {
					//Check if each autocomplete item is a case-insensitive match on the input
					var item = $(this).data("ui-autocomplete-item");
					if (matcher.test(item.Name)) {
						//There was a match, lets stop checking
						autocomplete.selectedItem = item;
						return false;
					}
				});
				//if there was a match trigger the select event on that match
				//I would recommend matching the label to the input in the select event
				if (autocomplete.selectedItem) {
					autocomplete._trigger("select", event, {
						item: autocomplete.selectedItem
					});
					//there was no match, clear the input
				}
			});
		},
		loadAdGroupAutocomplete = function (selector) {
			var lastXhr = '',
			noMatches,
			itemCounter = 0,
			itemCount,
			itemWasAdded;
			$(selector).autocomplete({
				source: function (request, response) {
					lastXhr = Wdot.Ajax.prototype.Get("/admin/ads/autocompleteforadgroups", request, function (data, status, xhr) {
						if (!data || data.length == 0) {
							noMatches = true;
							data.push({});
						}
						itemCount = data.length;
						if (xhr === lastXhr) {
							response(data);
						}
					});
				},
				focus: function (event, ui) {
					$(selector).val(ui.item.Name);
					return false;
				},
				select: function (event, ui) {
					$(selector).val(ui.item.Name);
					$(selector).attr("data-itemid", ui.item.Id);
					return false;
				},
				minLength: 1
			}).data("ui-autocomplete")._renderItem = function (ul, item) {
				if (!noMatches) {
					if (!cityIsAdded(item.Id)) {
						itemWasAdded = true;
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append("<a data-itemid='" + item.Id + "'>" + item.Name + "</a>")
						.appendTo(ul);
					}

					itemCounter++;

					if (itemCounter == itemCount && !itemWasAdded) {
						item.value = 'All matches have been assigned or there are no matches.';
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
					}

					return '';
				}
				noMatches = false;
				item.value = 'All matches have been assigned or there are no matches.';
				return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
			};
			$(selector).blur(function (event) {
				var autocomplete = $(this).data("ui-autocomplete");
				var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val()), "i");
				autocomplete.widget().children(".ui-menu-item").each(function () {
					//Check if each autocomplete item is a case-insensitive match on the input
					var item = $(this).data("ui-autocomplete-item");
					if (matcher.test(item.Name)) {
						//There was a match, lets stop checking
						autocomplete.selectedItem = item;
						return false;
					}
				});
				//if there was a match trigger the select event on that match
				//I would recommend matching the label to the input in the select event
				if (autocomplete.selectedItem) {
					autocomplete._trigger("select", event, {
						item: autocomplete.selectedItem
					});
					//there was no match, clear the input
				}
			});
		},
		loadCityAutocomplete = function (selector) {
			var lastXhr = '',
			noMatches,
			itemCounter = 0,
			itemCount,
			itemWasAdded;
			$(selector).autocomplete({
				source: function (request, response) {
					lastXhr = Wdot.Ajax.prototype.Get("/admin/cities/autocompleteforcities", request, function (data, status, xhr) {
						if (!data || data.length == 0) {
							noMatches = true;
							data.push({});
						}
						itemCount = data.length;
						if (xhr === lastXhr) {
							response(data);
						}
					});
				},
				focus: function (event, ui) {
					$(selector).val(ui.item.Name);
					return false;
				},
				select: function (event, ui) {
					$(selector).val(ui.item.Name);
					$(selector).attr("data-itemid", ui.item.Id);
					return false;
				},
				minLength: 1
			}).data("ui-autocomplete")._renderItem = function (ul, item) {
				if (!noMatches) {
					if (!cityIsAdded(item.Id)) {
						itemWasAdded = true;
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append("<a data-itemid='" + item.Id + "'>" + item.Name + (item.Counties ? (", in " + item.Counties) : "") + "</a>")
						.appendTo(ul);
					}

					itemCounter++;

					if (itemCounter == itemCount && !itemWasAdded) {
						item.value = 'All matches have been assigned or there are no matches.';
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
					}

					return '';
				}
				noMatches = false;
				item.value = 'All matches have been assigned or there are no matches.';
				return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
			};
			$(selector).blur(function (event) {
				var autocomplete = $(this).data("ui-autocomplete");
				var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val()), "i");
				autocomplete.widget().children(".ui-menu-item").each(function () {
					//Check if each autocomplete item is a case-insensitive match on the input
					var item = $(this).data("ui-autocomplete-item");
					if (matcher.test(item.Name)) {
						//There was a match, lets stop checking
						autocomplete.selectedItem = item;
						return false;
					}
				});
				//if there was a match trigger the select event on that match
				//I would recommend matching the label to the input in the select event
				if (autocomplete.selectedItem) {
					autocomplete._trigger("select", event, {
						item: autocomplete.selectedItem
					});
					//there was no match, clear the input
				}
			});
		},
		loadCountyAutocomplete = function (selector) {
			var lastXhr = '',
			noMatches,
			itemCounter = 0,
			itemCount,
			itemWasAdded;
			$(selector).autocomplete({
				source: function (request, response) {
					lastXhr = Wdot.Ajax.prototype.Get("/admin/counties/autocomplete", request, function (data, status, xhr) {
						if (!data || data.length == 0) {
							noMatches = true;
							data.push({});
						}
						itemCount = data.length;
						if (xhr === lastXhr) {
							response(data);
						}
					});
				},
				focus: function (group, ui) {
					$(selector).val(ui.item.Name);
					return false;
				},
				select: function (event, ui) {
					$(selector).val(ui.item.Name);
					$(selector).attr("data-itemid", ui.item.Id);
					return false;
				},
				minLength: 1
			}).data("ui-autocomplete")._renderItem = function (ul, item) {
				if (!noMatches) {
					if (!countyIsAdded(item.Id)) {
						itemWasAdded = true;
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append("<a data-itemid='" + item.Id + "'>" + item.Name + "</a>")
						.appendTo(ul);
					}

					itemCounter++;

					if (itemCounter == itemCount && !itemWasAdded) {
						item.value = 'All matches have been assigned or there are no matches.';
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
					}

					return '';
				}
				noMatches = false;
				item.value = 'All matches have been assigned or there are no matches.';
				return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
			};

			$(selector).blur(function (event) {
				var autocomplete = $(this).data("ui-autocomplete");
				var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val()), "i");
				autocomplete.widget().children(".ui-menu-item").each(function () {
					//Check if each autocomplete item is a case-insensitive match on the input
					var item = $(this).data("ui-autocomplete-item");
					if (matcher.test(item.Name)) {
						//There was a match, lets stop checking
						autocomplete.selectedItem = item;
						return false;
					}
				});
				//if there was a match trigger the select event on that match
				//I would recommend matching the label to the input in the select event
				if (autocomplete.selectedItem) {
					autocomplete._trigger("select", event, {
						item: autocomplete.selectedItem
					});
					//there was no match, clear the input
				}
			});
		},
		loadRegionAutocomplete = function (selector) {
			var lastXhr = '',
			noMatches,
			itemCounter = 0,
			itemCount,
			itemWasAdded;
			$(selector).autocomplete({
				source: function (request, response) {
					lastXhr = Wdot.Ajax.prototype.Get("/admin/regions/autocomplete", request, function (data, status, xhr) {
						if (!data || data.length == 0) {
							noMatches = true;
							data.push({});
						}
						itemCount = data.length;
						if (xhr === lastXhr) {
							response(data);
						}
					});
				},
				focus: function (group, ui) {
					$(selector).val(ui.item.Name);
					return false;
				},
				select: function (event, ui) {
					$(selector).val(ui.item.Name);
					$(selector).attr("data-itemid", ui.item.Id);
					return false;
				},
				minLength: 1
			}).data("ui-autocomplete")._renderItem = function (ul, item) {
				if (!noMatches) {
					if (!regionIsAdded(item.Id)) {
						itemWasAdded = true;
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append("<a data-itemid='" + item.Id + "'>" + item.Name + "</a>")
						.appendTo(ul);
					}

					itemCounter++;

					if (itemCounter == itemCount && !itemWasAdded) {
						item.value = 'All matches have been assigned or there are no matches.';
						return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
					}

					return '';
				}
				noMatches = false;
				item.value = 'All matches have been assigned or there are no matches.';
				return $("<li></li>")
						.data("ui-autocomplete-item", item)
						.append('All matches have been assigned or there are no matches.')
						.appendTo(ul);
			};

			$(selector).blur(function (event) {
				var autocomplete = $(this).data("ui-autocomplete");
				var matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val()), "i");
				autocomplete.widget().children(".ui-menu-item").each(function () {
					//Check if each autocomplete item is a case-insensitive match on the input
					var item = $(this).data("ui-autocomplete-item");
					if (matcher.test(item.Name)) {
						//There was a match, lets stop checking
						autocomplete.selectedItem = item;
						return false;
					}
				});
				//if there was a match trigger the select event on that match
				//I would recommend matching the label to the input in the select event
				if (autocomplete.selectedItem) {
					autocomplete._trigger("select", event, {
						item: autocomplete.selectedItem
					});
					//there was no match, clear the input
				}
			});
		},
		itemIsIncluded = function (id) {
			return $.inArray(id.toString(), $(includeItems()).map(function () { return this.id || 0; })) > -1;
		},
		itemIsExcluded = function (id) {
			return $.inArray(id.toString(), $(excludeItems()).map(function () { return this.id || 0; })) > -1;
		},
		containsTag = function (tag) {
			return $.inArray(tag.toString(), $(tags()).map(function () { return this.name != undefined ? this.name.toString() : ''; })) > -1;
		},
		resetListingViewModel = function () {
			includeItems([]);
			excludeItems([]);
			categories([]);
			selectedCategories([]);
			onlyFeaturedListings(false);
			numResults('');
			tags([]);
			contenttype(undefined);
			displaytype(undefined);
			cities([]);
			counties([]);
			regions([]);
			attributes([]);
			selectedAttribute(undefined);
			attributeFilters([]);
		},
		selectedAttribute = ko.observable(),
		selectedAttributeDetails = ko.computed(function () {
			if (selectedAttribute() != undefined) {
				var attribute = getAttributeById(selectedAttribute());
				return { name: "Attribute Name: " + attribute.name, description: "Attribute Description: " + attribute.description, type: "Attribute Type: " + attribute.type.Name };
			}
			return null;
		}),
		attributes = ko.observableArray(),
		operator = ko.observable(),
		filterValue = ko.observable(),
		filterIndex = ko.observable(0),
		attributeFilters = ko.observableArray(),
		addAttribute = function () {
			if (selectedAttribute() != undefined && operator() != 0 && filterValue() != '') {
				if (filterIndex() == 0) {
					var attribute = new attributeFilter();
					attribute.id(selectedAttribute()); ;
					attribute.operator(operator());
					attribute.value(filterValue());
					attributeFilters.push(attribute);
				} else {
					$(attributeFilters()).each(function () {
						if (this.index() == filterIndex()) {
							this.id(selectedAttribute());
							this.operator(operator());
							this.value(filterValue());
						}
					});
					filterIndex(0);
				}
				selectedAttribute(undefined);
				operator(0);
				filterValue('');
			}
		},
		getAttributeNameById = function (id) {
			var name = '';
			$(attributes()).each(function () {
				if (this.id == id) {
					name = this.name;
					return false;
				}
			});
			return name;
		},
		getAttributeById = function (id) {
			var attribute = {};
			$(attributes()).each(function () {
				if (this.id == id) {
					attribute = this;
					return false;
				}
			});
			return attribute;
		},
		saveAttributes = function () {
			$("#listing-attributes-edit").addClass('hidden');
			$("#listing-main-view").removeClass('hidden');
			$("#widget-buttons").removeClass('hidden');
		},
		editFilters = function () {
			$("#listing-attributes-edit").removeClass('hidden');
			$("#listing-main-view").addClass('hidden');
			$("#widget-buttons").addClass('hidden');
		},
		editFilter = function (d) {
			operator(d.operator());
			selectedAttribute(d.id());
			filterValue(d.value());
			filterIndex(d.index());
		},
		deleteFilter = function (d) {
			attributeFilters.remove(d);
		};
		contenttype.subscribe(function (value) {
			if (value != undefined && value != 0) {
				Wdot.Ajax.prototype.Post('/admin/categories/getcategoriesforcontenttype/' + value, null, function (d) {
					//if(_log) console.log('contenttype.subscribe.post.d:', d);
					if(_log) console.log('Widgets.contenttype.subscribe.post.d parsed:', ko.utils.parseJson(d));

					var parsedCats = ko.utils.parseJson(d);

					categoriesFlat = [];
					parsedCats.forEach(cat => {
						//if(_log) console.log('cat:', cat);
						categoriesFlat.push({id: cat.id, name: cat.name, prefix: '', parentId: null});
						if(cat.children.length > 0){
							//if(_log) console.log('found children');
							loopThroughCats(cat.children, '', cat.id);
						}
					});
					if(_log) console.log('Widgets.contenttype.subscribe.post - categoriesFlat:', categoriesFlat);

					//categories(ko.utils.parseJson(d));//orig
					categories(categoriesFlat);
					selectedCategories(selectedCategories());
				});
			}
		});
		attributeFilters.subscribe(function (value) {
			$(attributeFilters()).each(function (i) {
				this.index((i + 1));
			});
		});
		throttledSelectedCategories.subscribe(function (value) {
			if (value != undefined && value != 0) {
				Wdot.Ajax.prototype.Post('/admin/attributes/getforcategories', { categories: value.join(",") }, function (d) {
					attributes(d);
					attributeFilters(attributeFilters());
				});
			}
		});

		var categoriesFlat = [],
			prefixChar = '-';
		var loopThroughCats = function (cats, prefix, parentId) {
			cats.forEach(cat => {
				var newPrefix = prefix + prefixChar;
				categoriesFlat.push({id: cat.id, name: cat.name, prefix: newPrefix, parentId: parentId});
				if(cat.children.length > 0){
					//if(_log) console.log('found children');
					loopThroughCats(cat.children, newPrefix);
				}
			});
		}

		return {
			selectedAttributeDetails: selectedAttributeDetails,
			getAttributeNameById: getAttributeNameById,
			throttledSelectedCategories: throttledSelectedCategories,
			filterIndex: filterIndex,
			editFilter: editFilter,
			deleteFilter: deleteFilter,
			editFilters: editFilters,
			selectedAttribute: selectedAttribute,
			attributes: attributes,
			operator: operator,
			filterValue: filterValue,
			attributeFilters: attributeFilters,
			addAttribute: addAttribute,
			saveAttributes: saveAttributes,
			contenttype: contenttype,
			contentTypes: contentTypes,
			displaytype: displaytype,
			displayTypes: displayTypes,
			recommendedDisplayTypes: recommendedDisplayTypes,
            trendingDisplayTypes: trendingDisplayTypes,
			editCategories: editCategories,
			categories: categories,
			categoriesAsString: categoriesAsString,
			tags: tags,
			addTag: addTag,
			removeTag: removeTag,
			includeItems: includeItems,
			excludeItems: excludeItems,
			addIncludeItem: addIncludeItem,
			removeIncludeItem: removeIncludeItem,
			addExcludeItem: addExcludeItem,
			removeExcludeItem: removeExcludeItem,
			onlyFeaturedListings: onlyFeaturedListings,
			numResults: numResults,
			numResultsOptions: numResultsOptions,
			saveCategories: saveCategories,
			selectedCategories: selectedCategories,
			loadContentAutocomplete: loadContentAutocomplete,
			resetListingViewModel: resetListingViewModel,
			isCategorySelected: isCategorySelected,
			cities: cities,
			counties: counties,
			regions: regions,
			addCity: addCity,
			removeCity: removeCity,
			addCounty: addCounty,
			removeCounty: removeCounty,
			addRegion: addRegion,
			removeRegion: removeRegion,
			loadCityAutocomplete: loadCityAutocomplete,
			loadCountyAutocomplete: loadCountyAutocomplete,
			loadRegionAutocomplete: loadRegionAutocomplete,
			displaytypeForDisplay: displaytypeForDisplay,
			displaytypeForRecommended: displaytypeForRecommended,
			displaytypeForTrending: displaytypeForTrending,
			categoriesForDisplay: categoriesForDisplay,
			contentTypeForDisplay: contentTypeForDisplay,
			allCategories: allCategories
		};
	} (),
	modalWindowHeight = undefined,
	modalWindowWidth = undefined,
	contentCreationViewModel = function () {
		var widgetsJson = ko.observableArray(),
		updateZone = function (key, zoneId) {
			$(widgetsJson()).each(function () {
				if (this.key == key) {
					this.zone = zoneId;
				}
			});
			widgetsJson.valueHasMutated();
		},
		remove = function (item) {
			var answer = confirm("Are you sure you would like to permanently remove this widget?");
			if (answer) {
				var key = item.attr("data-key");
				$(widgetsJson()).each(function (i) {
					if (this.key == key) {
						widgetsJson.splice(i, 1);
						item.remove();
					}
				});
			}
		},
		populateEditFormFunction = ko.observable(),
		widgetForEditForm = ko.observable(),
		edit = function (item) {
			//if(_log) console.log('edit.item', item);
			var key = item.attr("data-key");
			widgetForEditForm(getWidgetByKey(key));
            Wdot.ModalWindow.loadAjaxPost(wdot.Widgets.modalWindowWidth ? wdot.Widgets.modalWindowWidth : "auto", wdot.Widgets.modalWindowHeight ? wdot.Widgets.modalWindowHeight : "auto", "/admin/widgets/getwidgeteditmodal/" + item.attr("data-codename"), { zone: item.parent().attr("data-zoneId"), data: $.parseJSON(item.attr("data-ajaxPostData")) }, function () { populateEditFormFunction.valueHasMutated(); $('#dialog').dialog('option', 'position', 'center'); });
			item.attr("data-isInEditMode", true);
		},
		updateDisplayOrder = function () {
            $(widgetsJson()).each(function () {
				var self = this;
				var key = self.key;
				if (self.zone != "widget-jail") {
					$("#widget-container .widget-item").each(function (index) {
						if ($(this).attr("data-key") == key) {
							self.displayOrder = index + 1;
						}
					});
				}
				else {
					self.displayOrder = 0;
				}
			});
		},
		widgetsJsonString = ko.computed(function () {
			updateDisplayOrder();
			return ko.utils.stringifyJson(widgetsJson());
		}),
        loadWidgets = function (json) {
			var r = json;
			if (r != undefined) {
                var zoneIds = $("#widget-container [data-type='widget-zone']").map(function () { return $(this).attr("data-zoneid"); }).get().join(',') + ",widget-jail";
                var zoneIdArray = zoneIds.split(',');

				$(r.root.widget).each(function (index) {
                    var widget = this;
                    if (zoneIdArray.indexOf(widget.zone) < 0) {
						widget.zone = 'widget-jail';
					}
					var html = $("#widget-list .widget-item[data-id='" + widget.id + "']").clone();
					loadWidget(html, widget, index);
				});
			}
		},
		loadNewTemplate = function () {
			$(widgetsJson()).each(function (i) {
				if (this.zone != "widget-jail") {
					var html = $("#widget-list .widget-item[data-id='" + this.id + "']").clone();
					var zoneIds = $("#widget-container [data-type='widget-zone']").map(function () { return $(this).attr("data-zoneid"); }).get().join(',') + ",widget-jail";
					if (zoneIds.indexOf(this.zone + ',') < 0) {
						this.zone = 'widget-jail';
					}
					$(html).html("<div class=\"widget-content currentTemplate\" data-bind=\"template: '" + $(html).attr("data-codename") + "_template'\"></div></div>");
					$(html).attr("data-key", this.key);
					$(html).attr("data-isnew", false);
					$(html).attr("data-isineditmode", false);
					html.append("<a href='javascript:void(0)' class='widget-remove'>Remove</a>");
					html.append("<a href='javascript:void(0)' class='widget-edit'>Edit</a>");
					if (this.zone != 'widget-jail') {
						$("#widget-container [data-type='widget-zone'][data-zoneid='" + this.zone + "']").append(html);
					} else {
						$("#widget-jail").append(html);
					}
					ko.applyBindings(this.properties, $(".currentTemplate")[0]);
					$(".currentTemplate").removeClass("currentTemplate");
				}
			});
		},
		processBeforeAfterHtml = function (html) {
			if (html == 'null' || html == 'undefined')
				return '';

			return html;
		},
		loadWidget = function (html, widget, index) {
			var htmlBefore = widget.properties.htmlBefore != null ? String(processBeforeAfterHtml(widget.properties.htmlBefore)).replace(/&amp;/g, '&')
								.replace(/&quot;/g, '"')
								.replace(/&#39;/g, '\'')
								.replace(/&lt;/g, '<')
								.replace(/&gt;/g, '>') : '';

			var htmlAfter = widget.properties.htmlAfter != null ? String(processBeforeAfterHtml(widget.properties.htmlAfter)).replace(/&amp;/g, '&')
								.replace(/&quot;/g, '"')
								.replace(/&#39;/g, '\'')
								.replace(/&lt;/g, '<')
								.replace(/&gt;/g, '>') : '';

			$(html).html(htmlBefore + "<div class=\"widget-content currentTemplate\" data-bind=\"template: '" + $(html).attr("data-codename") + "_template'\"></div>" + htmlAfter);
			$(html).attr("data-key", index + 1);
			$(html).attr("data-isnew", false);
			$(html).attr("data-isineditmode", false);
			html.append("<a href='javascript:void(0)' class='widget-remove'>Remove</a>");
			html.append("<a href='javascript:void(0)' class='widget-edit'>Edit</a>");
			if (widget.zone != 'widget-jail') {
				$("#widget-container [data-type='widget-zone'][data-zoneid='" + widget.zone + "']").append(html);
			} else {
				$("#widget-jail").append(html);
            }

            try {
                ko.applyBindings(widget.properties, $(".currentTemplate")[0]);
            }
            catch (e) { }

			$(".currentTemplate").removeClass("currentTemplate");
			recursiveEncodingProperties(widget.properties);
			widgetsJson.push({ id: widget.id, zone: widget.zone, properties: widget.properties, key: index + 1, isNew: false });
			wdot.Widgets.widgetPropertiesViewModel.key(wdot.Widgets.widgetPropertiesViewModel.key() + 1);
			wdot.Widgets.widgetPropertiesViewModel.currentKey++;
		},
			recursiveEncodingProperties = function (obj) {
				for (var k in obj) {
					if (typeof obj[k] == "object") {
						recursiveEncodingProperties(obj[k]);
					}
					else {
						if (k == "title" || k == "summary" || k == "textarea") {
							obj[k] = wdot.Utils.HtmlDecode(obj[k]);
						}
					}
				}
			},
		getWidgetByKey = function (key) {
			var widget;
			$(widgetsJson()).each(function () {
				if (this.key == key) {
					widget = this;
				}
			});
			return widget;
		};
		widgetPropertiesViewModel.widgetAsJson.subscribe(function (value) {
			if (value.isNew) {
				widgetsJson.push(value);
			} else {
				$(widgetsJson()).each(function () {
					if (this.key == value.key) {
						this.id = value.id;
						this.zone = value.zone;
						this.properties = value.properties;
						this.isNew = false;
						widgetsJson.valueHasMutated();
					}
				});
			}
		});
		populateEditFormFunction.subscribe(function (value) {
			if (widgetForEditForm() != undefined && !widgetForEditForm().isNew) {
				value(widgetForEditForm());
			}
			widgetForEditForm(null);
		});

		var removeMsCategories = function () {
			if(_log) console.log('removeMsCategories');
			if($('.js-ms-categories').length > 0) {
				$('.multiselect_sortable_hide').addClass('js-ms-categories multiselectsortable');
				setTimeout(() => {
					//$('.ui-dialog').remove();
					$('.multiselect_sortable_content').remove();
					if(_log) console.log('remove js-ms-categories');
				}, 1000);
				//initializedCategories = false;
			}
		}

		$(document).on('click', '#widget-container .widget-edit', function (e) {
			//if(_log) console.log('click widget-edit e:', e.currentTarget);
			edit($(e.currentTarget).parent());
		});
		$(document).on('click', '#widget-container .widget-remove', function (e) {
			remove($(e.currentTarget).parent());
		});

		$(document).on('click', '.ui-dialog-titlebar-close', function(event) {
			if(_log) console.log('closed button event:', event);
			//removeMsCategories();
			initializedCategories = false;
		});
		// $('#dialog').on('dialogclose', function(event) {
		// 	if(_log) console.log('closed dialog');
		// 	//todojk might need to add removal here too
		// });
		return {
			widgetsJson: widgetsJson,
			widgetsJsonString: widgetsJsonString,
			loadWidgets: loadWidgets,
			updateZone: updateZone,
			remove: remove,
			edit: edit,
			populateEditFormFunction: populateEditFormFunction,
			getWidgetByKey: getWidgetByKey,
			loadNewTemplate: loadNewTemplate,
			ContentPageBlogArticlesViewModel: wdot.SelectedBlogArticles.ContentPageBlogArticlesViewModel,
			//removeMsCategories: removeMsCategories
		};
	} ();
	return {
		contentCreationViewModel: contentCreationViewModel,
		widgetPropertiesViewModel: widgetPropertiesViewModel,
		currentKey: currentKey,
		mediaLibraryViewModel: mediaLibraryViewModel,
		youTubeViewModel: youTubeViewModel,
		listingCreationViewModel: listingCreationViewModel,
		Media: media,
		ContentType: contentType,
		ListItem: listItem,
		attributeFilter: attributeFilter,
		processListProperty: processListProperty,
		modalWindowHeight: modalWindowHeight,
		modalWindowWidth: modalWindowWidth,
		youTubeVideo: youTubeVideo
	};
} ();

