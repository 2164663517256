var tw = tw || {};
tw.Analytics = function () {

    var _log = false;

    var ga4 = function (category, action, label, eventName = 'onclick') {
        if (_log) console.group('tw.Analytics.ga4()');
        if (_log) console.log('category: ', category);
        if (_log) console.log('action: ', action);
        if (_log) console.log('label: ', label);
        if (_log) console.log('eventName: ', eventName);
        if (_log) console.groupEnd();

        var options = {
            event: eventName,
            category: category,
            action: action,
            label: label,
            page: { 
                protocol: window.location.protocol,
                hostname: window.location.hostname,
                path: window.location.pathname,
                search: window.location.search
            }
        };

        var parentUrl = getParentUrl();
        if(parentUrl){
            options['referrer'] = parentUrl;
            //console.log('tw.Analytics.parentUrl:', parentUrl);
        }

        window['dataLayer'].push(options);
        if (_log) console.log('tw.Analytics.window[dataLayer]:', window['dataLayer']);

    }


    /**
     * Gets parent url if is in an iframe
     * @returns 
    */
    var getParentUrl = function () {
        var isInIframe = (parent !== window),
            parentUrl = null;
    
        if (isInIframe) {
            parentUrl = document.referrer;
        }
        if (_log) console.log('tw.Analytics.getParentUrl.isInIframe:', isInIframe, '=', parentUrl);

        return parentUrl;
    }

    return {
        ga4: ga4
    }

}();