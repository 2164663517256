var wdot = wdot || {};

wdot.ContentPages = function () {
	var _log = false;
	if(_log)console.log('wdot.ContentPages loaded');

	var fancyDirectory = {
		over: function () {
			var position = $(this).position();
			var vertposition = position.top + 6;
			$('span.leftArrow').css("top", vertposition);
			$('span.rightArrow').css("top", vertposition);
			$(this).siblings().removeClass('active hover');
			$(this).addClass('active hover');
			wdot.ContentPages.DirectoryListing.MovePin($(this).attr("data-lat"), $(this).attr("data-long"));
		}, // function = onMouseOver callback (REQUIRED)
		timeout: 600, // number = milliseconds delay before onMouseOut
		out: function () {
			$(this).removeClass('hover');
		} // function = onMouseOut callback (REQUIRED)
	};

	var directoryListing = function () {
		var map,
		cityMap = null,
		roadTripMap = null,
		cityPageListingTypes = { RoadTrip: "roadtrips", Cities: "cities" },
		loadCityPageMap = function (type, pinLat, pinLong, multiPinPointsJSONString) {
			var selector = type == cityPageListingTypes.Cities ? $(".regionDirectory .citiesList .directoryMap") : $(".regionDirectory .roadTripsList .directoryMap");
			var currentMap = type == cityPageListingTypes.RoadTrip ? roadTripMap : cityMap;

			if (currentMap == null) {
				var latitude = 44.832017349893526,
				longitude = -89.84687250000002;
				var center = new Microsoft.Maps.Location(latitude, longitude);
				currentMap = new Microsoft.Maps.Map(selector[0], {
				    enableSearchLogo: false,
				    showMapTypeSelector: false,
				    credentials: wdot.BingMaps.apiKey,
				    zoom: 11,
				    center: center,
				    disableKeyboardInput: true
				});
				wdot.BingMaps.bindMethodToPreventPinterestPinningMapTiles(currentMap);
				if (type == cityPageListingTypes.RoadTrip) {
					roadTripMap = currentMap;
				} else {
					cityMap = currentMap;
				}

				selector.bind('contextmenu', function (event) {
					return false;
				});
			}

			currentMap.entities.clear();
			var pinOptions = { visible: true, draggable: false };
			//pinOptions = wdot.BingMaps.PinImageWithOutText(pinOptions);
            var factory = new Wdot.BingMaps.Factory.PinOptionsFactory();
            pinOptions = factory.getPinOptions(Wdot.Enums.PushPinOptionsEnum.PinImageWithOutText);

			if (type == "roadtrips" && multiPinPointsJSONString != undefined && multiPinPointsJSONString != "") {
				var points = JSON.parse(multiPinPointsJSONString);
				$(points).each(function () {
					var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(this.Latitude, this.Longitude), pinOptions);
					currentMap.entities.push(pin);
				});
			} else {
				var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(pinLat, pinLong), pinOptions);
				currentMap.entities.push(pin);
			}

			bestViewCitiesPage(type, currentMap);
			$.debounce(250, selector.removeClass("hidden"));
		},

		loadMap = function (pinLat, pinLong) {
			if (map != null) {
				movePin(pinLat, pinLong);
				return;
			}

			var latitude = 44.832017349893526,
				longitude = -89.84687250000002;

			var center = new Microsoft.Maps.Location(latitude, longitude);
			map = new Microsoft.Maps.Map($(".directoryMap")[0], {
			    enableSearchLogo: false,
			    showMapTypeSelector: false,
			    credentials: wdot.BingMaps.apiKey,
			    zoom: 11,
			    center: center,
			    disableKeyboardInput: true
			});
			wdot.BingMaps.bindMethodToPreventPinterestPinningMapTiles(map);
			var pinOptions = { visible: true, draggable: false };
			//pinOptions = wdot.BingMaps.PinImageWithOutText(pinOptions);
            var factory = new Wdot.BingMaps.Factory.PinOptionsFactory();
            pinOptions = factory.getPinOptions(Wdot.Enums.PushPinOptionsEnum.PinImageWithOutText);
			var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(pinLat, pinLong), pinOptions);
			map.entities.push(pin);
			bestView();
			$(".directoryMap").bind('contextmenu', function (event) {
				return false;
			});

			$.debounce(250, $(".directoryMap").removeClass("hidden"));
		},

		movePin = function (lat, lon) {
			if (map != null) {
				map.entities.get(0).setLocation(new Microsoft.Maps.Location(lat, lon));
				bestView();
			}
		},

		bestViewCitiesPage = function (type, map) {
			var selector = type == cityPageListingTypes.Cities ? $(".regionDirectory .citiesList .directoryMap") : $(".regionDirectory .roadTripsList .directoryMap");
			var center = new Microsoft.Maps.Location(44.832017349893526, -89.84687250000002),
			zoomLevel = 0,
			maxLat = -85,
			minLat = 85,
			maxLon = -180,
			minLon = 180,
			length = map.entities.getLength(),
			buffer = 5,
			mapWidth = selector.width(),
			mapHeight = selector.height();

			for (var i = 0; i < length; i++) {
				var location = map.entities.get(i)._location;

				if (location.latitude > maxLat) {
					maxLat = location.latitude;
				}

				if (location.latitude < minLat) {
					minLat = location.latitude;
				}

				if (location.longitude > maxLon) {
					maxLon = location.longitude;
				}

				if (location.longitude < minLon) {
					minLon = location.longitude;
				}
			}

			var zoom1 = 15, zoom2 = 15;

			if (length > 0) {
				center.latitude = (maxLat + minLat) / 2;
				center.longitude = (maxLon + minLon) / 2;

				if (maxLon != minLon && maxLat != minLat) {
					zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
					zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
				}
			} else {
				zoom1 = 7;
				zoom2 = 7;
			}

			zoomLevel = (zoom1 < zoom2) ? zoom1 : zoom2;

			map.setView({ center: center, zoom: zoomLevel });
		},

		bestView = function () {
			var center = new Microsoft.Maps.Location(44.832017349893526, -89.84687250000002),
			zoomLevel = 0,
			maxLat = -85,
			minLat = 85,
			maxLon = -180,
			minLon = 180,
			length = map.entities.getLength(),
			buffer = 5,
			mapWidth = $(".directoryMap").width(),
			mapHeight = $(".directoryMap").height();

			for (var i = 0; i < length; i++) {
				var location = map.entities.get(i)._location;

				if (location.latitude > maxLat) {
					maxLat = location.latitude;
				}

				if (location.latitude < minLat) {
					minLat = location.latitude;
				}

				if (location.longitude > maxLon) {
					maxLon = location.longitude;
				}

				if (location.longitude < minLon) {
					minLon = location.longitude;
				}
			}

			var zoom1 = 15, zoom2 = 15;

			if (length > 0) {
				center.latitude = (maxLat + minLat) / 2;
				center.longitude = (maxLon + minLon) / 2;

				if (maxLon != minLon && maxLat != minLat) {
					zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
					zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
				}
			} else {
				zoom1 = 7;
				zoom2 = 7;
			}

			zoomLevel = (zoom1 < zoom2) ? zoom1 : zoom2;

			map.setView({ center: center, zoom: zoomLevel });
		},

		loadIndustryTravelGreenPage = function (currentDirectory, resetPaging, currentPageNum, getDefaultValues, qs, pushHistory) {
			wdot.Utils.ResetPlaceHolders();
			var pagingContainer = currentDirectory.find(".pagingContainer");
			var currentPage = currentPageNum || 1;
			var widgetId = currentDirectory.attr("data-unique-widget-id");
			var contentPageId = currentDirectory.attr("data-contentId");
			var baseContentId = currentDirectory.attr("data-basecontentid");
			pagingContainer.attr('data-currentpage', !resetPaging ? currentPageNum : 1);
			var pagingAjaxData = {};

			if (qs == null || qs == undefined || qs == {}) {
				if (!getDefaultValues) {
					pagingAjaxData.ContentPageId = contentPageId;
					pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
					pagingAjaxData.ContentPage_WidgetId = widgetId;
					pagingAjaxData.BaseContentId = baseContentId;
					var filters = currentDirectory.find(".fancy-directory__filters");

					if (filters.length != 0) {
						pagingAjaxData.SortBy = filters.find("select[name=SortBy]").val();
						pagingAjaxData.CategoryId = filters.find("select[name=CategoryId]").val();
					}
				} else {
					if ($("[name=DirectoryDefaultValue_" + widgetId + "]").length > 0 && $("[name=DirectoryDefaultValue_" + widgetId + "]").val() != "") {
						pagingAjaxData = JSON.parse($("[name=DirectoryDefaultValue_" + widgetId + "]").val());
					}

					pagingAjaxData.ContentPage_WidgetId = widgetId;
					pagingAjaxData.ContentPageId = contentPageId;
					pagingAjaxData.BaseContentId = baseContentId;
				}
			}

			if (pushHistory) {
				wdot.AjaxStateHandler.UpdateHistory('/Directory/' + pagingAjaxData.CurrentPage + '?' + $.param(pagingAjaxData));
			}

			Wdot.Ajax.prototype.Post("/ContentPage/IndustryTravelGreenListing?IsUpdate=true&" + (qs ? qs : ''), JSON.stringify(pagingAjaxData), function (d) {
				var uniqueWidgetId = $(d).map(function () { return $(this).attr("data-unique-widget-id"); }).get()[0];
				$('[data-unique-widget-id=' + uniqueWidgetId + ']').replaceWith(d);
				var count = $('[data-unique-widget-id=' + uniqueWidgetId + ']').attr("data-item-count");

				if (count != undefined) {
					$(".directoryList [href=#directory]").find(".count").html(" (" + count + ")");
				}

				placeHolderEvents();
				$('.fancy-directory__listitem:first').addClass("active");
				// Fancy directory - Testing for touch
				if (Modernizr.touch) {
					$('.fancy-directory').on('touchstart', '.fancy-directory__listitem', function () {
						var position = $(this).position();
						var vertposition = position.top + 7;
						$('span.listing-details__left-arrow').css("top", vertposition);
						$('span.listing-details__right-arrow').css("top", vertposition);
						$(this).siblings().removeClass('active');
						$(this).addClass('active');
						wdot.ContentPages.DirectoryListing.MovePin($(this).attr("data-lat"), $(this).attr("data-long"));
					});
				}
				else {
					// Directory Listing Hover Arrows
					var fancyDirectory = {
						over: function () {
							var position = $(this).position();
							var vertposition = position.top + 7;
							$('span.listing-details__left-arrow').css("top", vertposition);
							$('span.listing-details__right-arrow').css("top", vertposition);
							$(this).siblings().removeClass('active hover');
							$(this).addClass('active hover');
							wdot.ContentPages.DirectoryListing.MovePin($(this).attr("data-lat"), $(this).attr("data-long"));
						}, // function = onMouseOver callback (REQUIRED)
						timeout: 600, // number = milliseconds delay before onMouseOut
						out: function () {
							$(this).removeClass('hover');
						} // function = onMouseOut callback (REQUIRED)
					};
					$('.fancy-directory__listitem').hoverIntent(fancyDirectory);
				}
			}, null, 'application/json');
		},

                updateFilters = function() {
					//console.group('updateFilters()');
					//jk noticed during accessibility update 2023-02
					//note: legacy bug: all this code should be rewritten to concatinate into a single span instead of checking for commas in each span. 

					var //accessibilityResults = tw.AccessibilityFilter ? tw.AccessibilityFilter.getResultsStr() : '',
						categoryValue = $("#category-id").val();
					//console.log('accessibilityResults:', accessibilityResults);
					//console.log('categoryValue:', categoryValue);

                    if (categoryValue != 0) {
					//if (categoryValue != 0 || accessibilityResults != '') {
                        $("#category-name").empty();
                        $("#category-name").show();
						var selectedCategoriesStr = $("#category-id option:selected").text();
						//console.log('selectedCategoriesStr:', selectedCategoriesStr);
						if(categoryValue == 0) selectedCategoriesStr = '';
						// if(selectedCategoriesStr != '' && accessibilityResults != '') {
						// 	accessibilityResults = accessibilityResults + ', ';
						// 	console.log('accessibilityResults:', accessibilityResults);
						// }
						// var catName = accessibilityResults + selectedCategoriesStr;
						var catName = selectedCategoriesStr;
						//console.log('catname:', catName);
                        $("#category-name").html(catName);
                    }
					
                    var regionLabel = $("#region-label");
                    var region = $("#RegionId option:selected").text();
                    if (region != '' && region != 'Region' && !region.startsWith("Region")) {
                        regionLabel.html(", " + region);
                        regionLabel.show();
                    } else {
                        regionLabel.hide();
                        regionLabel.empty();
                    }
                    var petFriendlyLabel = $("label[for='cmn-toggle-171']");
                    var travelGreenLabel = $("label[for='cmn-toggle-269']");
                    if (petFriendlyLabel.hasClass('active')) {
                        $("#pet-friendly-label").html(", Pet Friendly");
                        $("#pet-friendly-label").show();
                    } else {
                        $("#pet-friendly-label").empty();
                        $("#pet-friendly-label").hide();
                    }
                    if (travelGreenLabel.hasClass('active')) {
                        $("#travel-green-label").html(", Travel Green");
                        $("#travel-green-label").show();
                    } else {
                        $("#travel-green-label").empty();
                        $("#travel-green-label").hide();
                    }

					updateSubFilterDisplay();

                    // var filters = $("#filter-labels");
                    // var firstFilter = $(filters).contents(":not(:empty)").first();
                    // var text = firstFilter.text();
                    // text = text.replace(", ", "");
                    // firstFilter.text(text);

					//console.groupEnd();
                },


				updateSubFilterDisplay = function () {
					//console.log('updateSubFilterDisplay()');
					var accessibilityResults = tw.AccessibilityFilter ? tw.AccessibilityFilter.getResultsStr() : '';
					//console.log('accessibilityResults:', accessibilityResults);


					//more filters - attributes
					var subCheckBoxes = $("#subFilters").find(".js-directory-attribute-container");//.active
					// console.log('subCheckBoxes.length:', subCheckBoxes.length);
					// console.log('subCheckBoxes::', subCheckBoxes);
					$("#subFilter-labels").empty();
					var labelCounter = 1;
					subCheckBoxes.each(function () {
						var thisText = $(this).find('.js-directory-attribute').text();
						var isActive = $(this).hasClass('active');
						//console.log('thisText:', thisText, isActive);
						if(isActive) {
							$("#subFilter-labels").append("<label id='filterLabel" + labelCounter + "'>" +
								", " + thisText + "</label>");
							labelCounter++;
						}
					});

					if(accessibilityResults != '') {
						$("#subFilter-labels").append("<label id='filterLabel" + labelCounter + "'>" +
						", " + accessibilityResults + "</label>");
						labelCounter++;
					}
					//debugger;
				},


				//check to see if page is Accessibility AND that the cookie for AccessibilityFilters exists and is populated
				checkAccessibilityFilters = function (pagingAjaxData, attributeCounter) {
					if (_log) console.group('checkAccessibilityFilters()');
					var location = window.location,
						cookieRaw = $.cookie('accessibilityFilters'),
						cookieAccessibilityFilters = cookieRaw ? JSON.parse(cookieRaw) : null,
						hasAccessibilityModal = $('.js-accessibility-modal').length;

					if (_log) console.log('location:', location);
					if (_log) console.log('pagingAjaxData:', pagingAjaxData);
					if (_log) console.log('attributeCounter:', attributeCounter);
					if (_log) console.log('pagingAjaxData.AttributeFilters:', pagingAjaxData.AttributeFilters);
					if (_log) console.log('pagingAjaxData.AttributeFilters.length:', pagingAjaxData.AttributeFilters.length);
					if (_log) console.log('cookieAccessibilityFilters:', cookieAccessibilityFilters);
					if (_log) console.log('hasAccessibilityModal:', hasAccessibilityModal);
					
					//debugger;

					if(hasAccessibilityModal && cookieAccessibilityFilters) {
						if (_log) console.log('**is accessibility, add accessibility filters to pagingAjaxData**');
						
						cookieAccessibilityFilters.forEach(item => {
							if (_log) console.log('cookieAccessibilityFilters.filter:', item);
							
							pagingAjaxData["AttributeFilters[" + attributeCounter.toString() + "].AttributeId"] = item.AttributeId;
							pagingAjaxData["AttributeFilters[" + attributeCounter.toString() + "].Value"] = item.Value;
							pagingAjaxData["AttributeFilters[" + attributeCounter.toString() + "].Name"] = item.Name;
							pagingAjaxData["AttributeFilters[" + attributeCounter.toString() + "].CoveoName"] = item.CoveoName;
							pagingAjaxData["AttributeFilters[" + attributeCounter.toString() + "].ResponseMethodId"] = item.ResponseMethodId;

							attributeCounter ++;

						});
					}

					if (_log) console.groupEnd();

					

					return pagingAjaxData;
				},

				updateFilterClearButtons = function (currentDirectory, pagingAjaxData, listType ) {
					if (_log) console.group('updateFilterClearButtons() 7');

					var cookieRaw = $.cookie('accessibilityFilters'),
						cookieAccessibilityFilters = cookieRaw ? JSON.parse(cookieRaw) : null,
						hasAccessibilityModal = $('.js-accessibility-modal').length,
						filters = currentDirectory.find(".filters");

					var winHash = window.location.hash,
						winHashArr = winHash.split('?'),
						hashParamObj = {};


					winHashArr[1].split("&").forEach(function(part) {
						var item = part.split("=");
						hashParamObj[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
					});

					if(hashParamObj != {}) {
						pagingAjaxData = hashParamObj;
					}

					if (_log) console.log('winHash:', winHash);
					//if (_log) console.log('hashParams:', hashParams);
					if (_log) console.log('hashParamObj:', hashParamObj);


					if (_log) console.log('cookieAccessibilityFilters:', cookieAccessibilityFilters);
					if (_log) console.log('hasAccessibilityModal:', hasAccessibilityModal);
					if (_log) console.log('filters:', filters);
					if (_log) console.log('pagingAjaxData:', pagingAjaxData);
					if (_log) console.log('listType:', listType);

					$('.js-filter-clear-buttons-loader button').each(function( index ) {
						//console.log( index + ": " + $( this ).text() );
						var isKeeper = $(this).hasClass('js-filter-form-submit') || $(this).hasClass('js-filter-form-clear') ? true : false;
						//console.log('isKeeper:', isKeeper);
						if(!isKeeper) {
							//console.log('remove this ^^');
							$(this).remove();
						}
					});

					//debugger;

					var btnsHtml = '';

					if(pagingAjaxData) {

						var url = window.location.href.toLowerCase(),
							hideDates = url.includes('this-weekend');

						//if (_log) console.log('hideDates:', hideDates);

						if(pagingAjaxData.Keyword && pagingAjaxData.Keyword != '') {
							btnsHtml += '<button type="button" class="js-filter-clear-button btn btn-light rounded-pill mb-2 mr-2 font-weight-light text-capitalize text-nowrap" data-filter="Keyword"><i class="fas fa-times  mr-2 fa-sm"></i><span>Keyword: ' + pagingAjaxData.Keyword + '</span></button>';
						}

						if(pagingAjaxData.StartDate && pagingAjaxData.StartDate != '' && !hideDates) {
							btnsHtml += '<button type="button" class="js-filter-clear-button btn btn-light rounded-pill mb-2 mr-2 font-weight-light text-capitalize text-nowrap" data-filter="StartDate"><i class="fas fa-times  mr-2 fa-sm"></i><span>Start Date: ' + pagingAjaxData.StartDate + '</span></button>';
						}

						if(pagingAjaxData.EndDate && pagingAjaxData.EndDate != '' && !hideDates) {
							btnsHtml += '<button type="button" class="js-filter-clear-button btn btn-light rounded-pill mb-2 mr-2 font-weight-light text-capitalize text-nowrap" data-filter="EndDate"><i class="fas fa-times  mr-2 fa-sm"></i><span>End Date: ' + pagingAjaxData.EndDate + '</span></button>';
						}
						
						if(pagingAjaxData.CityId && pagingAjaxData.City != '') {
							btnsHtml += '<button type="button" class="js-filter-clear-button btn btn-light rounded-pill mb-2 mr-2 font-weight-light text-capitalize text-nowrap" data-filter="City" data-cityid="' + pagingAjaxData.CityId + '"><i class="fas fa-times  mr-2 fa-sm"></i><span>' + pagingAjaxData.City + '</span></button>';
						}

						if(pagingAjaxData.RegionId && pagingAjaxData.RegionId != '0') {
							var regionName = $('#RegionId').find(":selected") ? $('#RegionId').find(":selected").text() : 'Region-' + pagingAjaxData.RegionId;
							//if(_log)console.log('regionName:', regionName);

							btnsHtml += '<button type="button" class="js-filter-clear-button btn btn-light rounded-pill mb-2 mr-2 font-weight-light text-capitalize text-nowrap" data-filter="RegionId"><i class="fas fa-times  mr-2 fa-sm"></i><span>' + regionName + '</span></button>';
						}

						if(pagingAjaxData.CategoryId && pagingAjaxData.CategoryId != '0') {
							var catName = $('#category-id').find(":selected") ? $('#category-id').find(":selected").text() : 'Category-' + pagingAjaxData.RegionId;
							//if(_log)console.log('catName:', catName);

							btnsHtml += '<button type="button" class="js-filter-clear-button btn btn-light rounded-pill mb-2 mr-2 font-weight-light text-capitalize text-nowrap" data-filter="CategoryId"><i class="fas fa-times  mr-2 fa-sm"></i><span>' + catName + '</span></button>';
							$('#category-id').val(pagingAjaxData.CategoryId);
						}
						
						if(pagingAjaxData.MileRange && pagingAjaxData.MileRange != '0') {
							var range = $('#mile-range').find(":selected") ? $('#mile-range').find(":selected").text() : 'MileRange-' + pagingAjaxData.RegionId;
							//if(_log)console.log('range:', range);

							btnsHtml += '<button type="button" class="js-filter-clear-button btn btn-light rounded-pill mb-2 mr-2 font-weight-light text-capitalize text-nowrap" data-filter="MileRange"><i class="fas fa-times  mr-2 fa-sm"></i><span>' + range + '</span></button>';
							$('#mile-range').val(pagingAjaxData.MileRange);
						}

						if(_log)console.log('pagingAjaxData.AttributeFilters:', pagingAjaxData.AttributeFilters);
						if(_log)console.log('pagingAjaxData[AttributeFilters[0]]:', pagingAjaxData['AttributeFilters[0]']);
						if(_log)console.log('pagingAjaxData[AttributeFilters[0].Name]:', pagingAjaxData['AttributeFilters[0].Name']);

						// if(pagingAjaxData['AttributeFilters[0].Name'] && pagingAjaxData['AttributeFilters[0].Name'].trim() != '') {
						if(winHash.includes('AttributeFilters')) {
							if(_log)console.log('FOUND ATTRIBUTES');
							//var loop = true;
							//for (var i = 0; loop; i++) {
							for (var i = 0; i < 50; i++) {
								var attrFilterName = pagingAjaxData['AttributeFilters[' + i + '].Name'];
								// if(_log)console.log('attrFilterName-' + i + ':', attrFilterName);
								// if(_log)console.log('attrFilterName-' + i + ':', decodeURI(attrFilterName));
								// if(_log)console.log('attrFilterName-' + i + ':', decodeURIComponent(attrFilterName));
                                if (attrFilterName != null) {
									var attrFilterId = pagingAjaxData['AttributeFilters[' + i + '].AttributeId'],
										attrFilterNameClean = decodeURI(attrFilterName).replace(/\+/g, ' ');
									// if(_log)console.log('attrFilterId-' + i + ':', attrFilterId);
									// if(_log)console.log('attrFilterNameClean:', attrFilterNameClean);

									$('.js-directory-attribute-container input[data-attribute-name="' + attrFilterNameClean + '"]').prop('checked', true);
									//$('.js-directory-attribute-container input[data-attribute-name="' + attrFilterNameClean + '"]').addClass('jkjk');
									$('.js-directory-attribute-container input[data-attribute-name="' + attrFilterNameClean + '"]').parent().parent().addClass('active');

									//debugger;
									btnsHtml += '<button type="button" class="js-filter-clear-button font-weight-light text-capitalize btn btn-light rounded-pill mb-2 mr-2  text-nowrap" data-filter="attributeFilter-' + i + '" data-attribute-id="' + attrFilterId + '"  data-attribute-index="' + i + '" data-attribute-name="' + attrFilterNameClean + '"><i class="fas fa-times  mr-2 fa-sm"></i><span>' + attrFilterNameClean + '</span></button>';
                                }
                                // else {
                                //     loop = false;
                                // }
							}
						}

					}

					if (_log) console.log('btnsHtml:', btnsHtml);
					//if (_log) console.log('$(.js-filter-clear-buttons-loader):', $('.js-filter-clear-buttons-loader').length);

					$('.js-filter-clear-buttons-loader').append(btnsHtml);

					setTimeout(() => {
						tw.AccessibilityFilter.updateAccessbilityFiltersArray();
						tw.AccessibilityFilter.updateFilterCountDisplay();
					}, 100);


					if (_log) console.groupEnd();

					$('.js-filter-clear-button').on('click', function () {
						if (_log) console.group('js-filter-clear-button.click');
						if (_log) console.log('js-filter-clear-button.click.href:', window.location.href);
						if (_log) console.log('js-filter-clear-button.click.pathname:', window.location.pathname);
						if (_log) console.log('js-filter-clear-button.click.hash:', window.location.hash);

						var newUrl = window.location.pathname + '#/directory?',
							hashParams = window.location.hash.split('?'),
							categoryQueryString = '',
							searchParams = new URLSearchParams(hashParams[1]),
							thisFilter = $(this).data('filter');

						if (_log) console.log('hashParams:', hashParams);
						if (_log) console.log('searchParams-orig:', searchParams);
						if (_log) console.log('thisFilter:', thisFilter);

						for (const [key, value] of searchParams.entries()) {
							if (_log) console.log(` - ${key}: ${value}`);
						}

						if(thisFilter == 'Keyword' && searchParams.has('Keyword')) {
							if (_log) console.log('FOUND Keyword^^^');
							$('#Keyword').val('');
							searchParams.delete('Keyword');
						}

						if(thisFilter == 'StartDate' && searchParams.has('StartDate')) {
							if (_log) console.log('FOUND StartDate^^^');
							$('#StartDate').val('');
							searchParams.delete('StartDate');
						}

						if(thisFilter == 'EndDate' && searchParams.has('EndDate')) {
							if (_log) console.log('FOUND EndDate^^^');
							$('#EndDate').val('');
							searchParams.delete('EndDate');
						}

						if(thisFilter == 'City' && searchParams.has('City')) {
							if (_log) console.log('FOUND City^^^');
							$('#City').val('');
							$("input[name=City]").attr("data-cityname", "");
							$("input[name=City]").attr("data-countyname", "");
							$("input[name=City]").attr("data-regionid", "");
							$("input[name=City]").attr("data-itemid", "");
							$("input[name=City]").attr("data-countyid", "0");
							searchParams.delete('City');
							searchParams.delete('CityId');
							searchParams.delete('CountyId');
						}

						if(thisFilter == 'RegionId' && searchParams.has('RegionId')) {
							if (_log) console.log('FOUND RegionId^^^');
							$('#RegionId').val('0');
							searchParams.delete('RegionId');
							//debugger;
						}

						if(thisFilter == 'CategoryId' && searchParams.has('CategoryId')) {
							if (_log) console.log('FOUND CategoryId^^^');
							$('#category-id').val('0');
							searchParams.delete('CategoryId');
						}

						if(thisFilter == 'MileRange' && searchParams.has('MileRange')) {
							if (_log) console.log('FOUND MileRange^^^');
							$('#mile-range').val('0');
							searchParams.delete('MileRange');
						}

						if(thisFilter.includes('attributeFilter')) {
							if (_log) console.log('FOUND attributeFilter^^^');
							var attrIndex = $(this).data('attribute-index'),
								attrId = $(this).data('attribute-id'),
								attrName = $(this).data('attribute-name');
							if (_log) console.log('attrIndex:', attrIndex, attrId, attrName);


							//note, there will be gaps in the array numbering once items have been deleted
							for(var i = 0; i < 50; i ++) {
								var curName = searchParams.get('AttributeFilters[' + i + '].Name');
								//console.log('curName:', curName);
								if(curName == attrName) {
									//console.log('^^^^ found it, delete');
									searchParams.delete('AttributeFilters[' + i + '].AttributeId');
									searchParams.delete('AttributeFilters[' + i + '].CoveoName');
									searchParams.delete('AttributeFilters[' + i + '].Name');
									searchParams.delete('AttributeFilters[' + i + '].ResponseMethodId');
									searchParams.delete('AttributeFilters[' + i + '].Value');
									//console.log('uncheckmark here');
									$('.js-directory-attribute-container [data-attribute-name="' + attrName + '"]').prop('checked', false);
								}
							}

							//clear cookie item out of accessibility
							tw.AccessibilityFilter.deleteAccessibilityFilter(attrId);
						}

						newUrl += searchParams.toString();

						//debugger;

						if (_log) console.log('searchParams-updated:', searchParams);
						if (_log) console.log('searchParams-updated:', searchParams.toString());
						if (_log) console.log('newUrl:', newUrl);
						//window.location.hash = '#' + searchParams.toString();
						history.pushState(null, null, newUrl);
						//$.address.hash(searchParams.toString());
						//wdot.AjaxStateHandler.UpdateHistory(listType + '?' + categoryQueryString + searchParams.toString());
						//window.location.href = newUrl;

						//todo-jk: this doesn't work  yet.
						//alert('this is still buggy. doesn\'t seem to work right yet for all filters');
						//try this: Directory.directoryExternalChange

						setTimeout(() => {
							//debugger;
							$('.js-filter-form-submit').trigger('click');
						}, 300);

						setTimeout(() => {
							updateSubFilterDisplay();
						}, 2000);
						

						if (_log) console.groupEnd();
					});
					//https://localhost:44332/things-to-do/outdoors#/directory?ContentPageId=695626&CurrentPage=1&ContentPage_WidgetId=467343&BaseContentId=86370&Keyword=&CityId=14423&City=Brookfield&County=Waukesha+County&CountyId=0&MileRange=&CategoryId=0&RegionId=711&PageType=place&AttributeFilters%5B0%5D.AttributeId=382&AttributeFilters%5B0%5D.Value=true&AttributeFilters%5B0%5D.Name=Wheelchair+Accessible&AttributeFilters%5B0%5D.CoveoName=WheelchairAccessibleBool&AttributeFilters%5B0%5D.ResponseMethodId=1

					
				},

                loadPage = function (currentDirectory, resetPaging, currentPageNum, getDefaultValues, qs, pushHistory, listType, isUserClicked, scrollTo) {

                    if (_log) console.group('loadPage()');
                    if (_log) console.log('loadPage().listType: ', listType);
                    if (_log) console.log('loadPage().currentDirectory: ', currentDirectory);
                    if (_log) console.log('loadPage().currentDirectory.data: ', currentDirectory.data);
                    if (_log) console.log('loadPage().currentDirectory.data.author: ', currentDirectory.data('author'));
                    if (_log) console.log('loadPage().currentDirectory.data.searchinput: ', currentDirectory.data('searchinput'));
                    if (_log) console.log('loadPage().resetPaging: ', resetPaging);
                    if (_log) console.log('loadPage().currentPageNum: ', currentPageNum);
                    if (_log) console.log('loadPage().getDefaultValues: ', getDefaultValues);
					if (_log) console.log('loadPage().qs: ', qs);
                    if (_log) console.log('loadPage().pushHistory: ', pushHistory);
                    if (_log) console.log('loadPage().listType: ', listType);
                    if (_log) console.log('loadPage().isUserClicked: ', isUserClicked);
                    if (_log) console.log('loadPage().scrollTo: ', scrollTo);
                    if (_log) console.groupEnd();
                    //debugger;
				    wdot.Utils.ResetPlaceHolders();
				    var pagingContainer = currentDirectory.find(".pagingContainer");
				    var currentPage = currentPageNum || 1;
				    var widgetId = currentDirectory.attr("data-unique-widget-id");
				    var contentPageId = currentDirectory.attr("data-contentId");
				    var baseContentId = currentDirectory.attr("data-basecontentid");
				    pagingContainer.attr('data-currentpage', !resetPaging ? currentPageNum : 1);
				    var pagingAjaxData = {};
                    var categoryQueryString = '';

                    if (scrollTo == undefined) {
                        scrollTo = true;
                    }

                    if (qs == null || qs == undefined || qs == {} || qs == "") {
						if (_log) console.log('loadPage().qs is empty. start new pagingAjaxData');

                        if (listType === undefined) {
                            listType = "directory";
                        }

                        if (!getDefaultValues && listType === "directory" || listType === "directory2023" || listType === "eventList") {

							if (_log) console.log('loadPage(). no qs, is directory');


                            pagingAjaxData.ContentPageId = contentPageId;

                            pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                            pagingAjaxData.ContentPage_WidgetId = widgetId;
                            pagingAjaxData.BaseContentId = baseContentId;
                            var filters = currentDirectory.find(".filters");

							if (_log) console.log('loadPage(). filters:', filters);

                            if (filters.length != 0) {
                                var cityFilter = filters.find("input[name=City]");
                                pagingAjaxData.Keyword = filters.find("input[name=Keyword]").val();

                                pagingAjaxData.CityId = cityFilter.attr("data-itemid");
                                pagingAjaxData.City = cityFilter.attr("data-cityname");

                                pagingAjaxData.County = cityFilter.attr("data-countyname");
                                pagingAjaxData.CountyId = cityFilter.attr("data-countyid");

                                if (typeof pagingAjaxData.County === "undefined" || pagingAjaxData.County.length == 0) {
                                    pagingAjaxData.County = filters.find("input[name=County]").val();
                                }

                                pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();
                                pagingAjaxData.CategoryId = filters.find("select[name=CategoryId]").val();

                                if (cityFilter.val()) {
                                    pagingAjaxData.RegionId = cityFilter.attr("data-regionid");
                                }

                                if (!pagingAjaxData.RegionId) {
                                    pagingAjaxData.CategoryId = filters.find("select[name=CategoryId]").val();
                                    pagingAjaxData.RegionId = filters.find("select[name=RegionId]").val();
                                }


                                pagingAjaxData.Keyword = filters.find("input[name=Keyword]").val();
                                pagingAjaxData.PageType = "place";
                            }

                            var i = 0;
                            pagingAjaxData.AttributeFilters = [];
							if(_log)console.log('dgfa.each:', $(".directoryGrid__filters-attributes input[type=checkbox]:checked").length);
                            ko.utils.arrayForEach($(".directoryGrid__filters-attributes input[type=textbox], .directoryGrid__filters-attributes input[type=checkbox]:checked, .topAttributes input[type=checkbox]:checked"), function (item) {
								if(_log)console.log('directoryGrid__filters--atribute.item:', item);
								//debugger;

                                pagingAjaxData["AttributeFilters[" + i.toString() + "].AttributeId"] = $(item).attr("data-attribute-id");
                                pagingAjaxData["AttributeFilters[" + i.toString() + "].Value"] = $(item).val();
                                pagingAjaxData["AttributeFilters[" + i.toString() + "].Name"] = $(item).attr("data-attribute-name");
                                pagingAjaxData["AttributeFilters[" + i.toString() + "].CoveoName"] = $(item).attr("data-attribute-coveo-name");
                                pagingAjaxData["AttributeFilters[" + i.toString() + "].ResponseMethodId"] = $(item).attr("data-attribute-response-method-id");

                                i++;
                            });
							//debugger;

							pagingAjaxData = checkAccessibilityFilters(pagingAjaxData, i);
							//updateFilterClearButtons(currentDirectory, pagingAjaxData );


                        } else if (listType === "articleList") {
                            pagingAjaxData.ContentPageId = contentPageId;
                            pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                            pagingAjaxData.ContentPage_WidgetId = widgetId;
							pagingAjaxData.BaseContentId = baseContentId;

							// For Stories filter
							//pagingAjaxData.CategoryId = currentDirectory.data("categoryid");
/*							pagingAjaxData.CategoryName = currentDirectory.data("categoryName");*/
							pagingAjaxData.SelectedCategoryName = decodeURIComponent(currentDirectory.data("selectedCategoryName")).replace(/\+/gi,' ');

                            pagingAjaxData.Author = decodeURIComponent(currentDirectory.data("author")).replace("+", " ");
							pagingAjaxData.SearchInput = decodeURIComponent(currentDirectory.data("searchinput")).replace("+", " ");

							if (pagingAjaxData.SearchInput == "undefined") {
								pagingAjaxData.SearchInput = "";
							}

                            if (pagingAjaxData.Author == "undefined") {
                                pagingAjaxData.Author = "";
                            }

                            pagingAjaxData.PageType = "article";
                        } else if (listType === "mediaList") {
                            pagingAjaxData.ContentPageId = contentPageId;
                            pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                            pagingAjaxData.ContentPage_WidgetId = widgetId;
                            pagingAjaxData.BaseContentId = baseContentId;
                            pagingAjaxData.CategoryId = currentDirectory.data("categoryid");
                            pagingAjaxData.PageType = "media";
                        } else {
							if(_log) console.log('regular place');
                            if ($("[name=DirectoryDefaultValue_" + widgetId + "]").length > 0 && $("[name=DirectoryDefaultValue_" + widgetId + "]").val() != "") {
                                pagingAjaxData = JSON.parse($("[name=DirectoryDefaultValue_" + widgetId + "]").val());
                            }

                            pagingAjaxData.ContentPage_WidgetId = widgetId;
                            pagingAjaxData.ContentPageId = contentPageId;
                            pagingAjaxData.BaseContentId = baseContentId;
                            pagingAjaxData.PageType = "place";

							//updateFilterClearButtons(currentDirectory, pagingAjaxData );

                        }

                        var queryString = window.location.search.replace("?", "").split("&");

                        for (var t = 0; t < queryString.length; t++) {
							if (queryString[t].split("=")[0] == "categoryId" || queryString[t].split("=")[0] == "category" || queryString[t].split("=")[0] == "selectedCategoryName" || queryString[t].split("=")[0] == "selectedCategoryName" || queryString[t].split("=")[0] == "articleauthor" || queryString[t].split("=")[0] == "articlecategory") {
                                categoryQueryString += "&" + queryString[t];
                            }
                        }
                    } else {

						if (_log) console.log('$.address.parameter("PageType"): ', $.address.parameter("PageType"));
						if (_log) console.log('loadPage(). found qs. set pagingAjaxData from $.address.parameter');

                        if ($.address.parameter("PageType") == 'place') {
                            pagingAjaxData.CurrentPage = parseInt($.address.parameter("CurrentPage")) || 0;
                            pagingAjaxData.ContentPageId = parseInt($.address.parameter("ContentPageId")) || 0;
                            pagingAjaxData.ContentPage_WidgetId = parseInt($.address.parameter("ContentPage_WidgetId")) || 0;
                            pagingAjaxData.BaseContentId = parseInt($.address.parameter("BaseContentId")) || 0;
                            pagingAjaxData.Keyword = $.address.parameter("Keyword");
                            pagingAjaxData.StartDate = $.address.parameter("StartDate");
                            pagingAjaxData.EndDate = $.address.parameter("EndDate");
                            pagingAjaxData.CityId = parseInt($.address.parameter("CityId")) || 0;
                            pagingAjaxData.CountyId = parseInt($.address.parameter("CountyId")) || 0;
                            pagingAjaxData.City = decodeURIComponent($.address.parameter("City")).replace("+", " ");
                            pagingAjaxData.County = decodeURIComponent($.address.parameter("County")).replace("+", " ");
                            pagingAjaxData.MileRange = parseInt($.address.parameter("MileRange")) || 0;
                            pagingAjaxData.RegionId = parseInt($.address.parameter("RegionId")) || 0;
                            pagingAjaxData.CategoryId = parseInt($.address.parameter("CategoryId")) || 0;
                            pagingAjaxData.PageSize = $.address.parameter("PageSize");
                            pagingAjaxData.PageType = "place";
                            var loop = true;
                            pagingAjaxData.AttributeFilters = [];
							var attributeCounter = 0;
                            for (var i = 0; loop; i++) {

                                if (typeof $.address.parameter(escape("AttributeFilters[" + i + "].AttributeId")) !== "undefined") {
									if(_log)console.log('AttributeFilters[' + i + ']:', 'AttributeFilters[' + i + ']');
									//debugger;

                                    pagingAjaxData["AttributeFilters[" + i + "].AttributeId"] = $.address.parameter(escape("AttributeFilters[" + i + "].AttributeId"));
                                    pagingAjaxData["AttributeFilters[" + i + "].Value"] = $.address.parameter(escape("AttributeFilters[" + i + "].Value"));
                                    pagingAjaxData["AttributeFilters[" + i + "].Name"] = $.address.parameter(escape("AttributeFilters[" + i + "].Name"));
                                    pagingAjaxData["AttributeFilters[" + i + "].CoveoName"] = $.address.parameter(escape("AttributeFilters[" + i + "].CoveoName"));
                                    pagingAjaxData["AttributeFilters[" + i + "].ResponseMethodId"] = $.address.parameter(escape("AttributeFilters[" + i + "].ResponseMethodId"));
									attributeCounter = i;
                                }
                                else {
                                    loop = false;
                                }
							}

							
							pagingAjaxData = checkAccessibilityFilters(pagingAjaxData, attributeCounter);


							if($('#place-directory-widget').width() > 10) {
								$('html, body').animate({
									'scrollTop': $('#place-directory-widget').offset().top
								}, 500);
							}
							
                        } else if ($.address.parameter("PageType") === "article") {
							if (_log) console.log('loadPage(). found qs. is article type');

							//pagingAjaxData.SelectedCategoryName = currentDirectory.data("categoryid");
							pagingAjaxData.SelectedCategoryName = decodeURIComponent(currentDirectory.data("SelectedCategoryName")).replace("+", " ");

                            //pagingAjaxData.ContentPageId = contentPageId;
                            pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                            //pagingAjaxData.ContentPage_WidgetId = widgetId;
                            //pagingAjaxData.BaseContentId = baseContentId;
                            //pagingAjaxData.CurrentPage = $.address.parameter("CurrentPage");
                            pagingAjaxData.ContentPageId = parseInt($.address.parameter("ContentPageId")) || 0;
                            pagingAjaxData.ContentPage_WidgetId = parseInt($.address.parameter("ContentPage_WidgetId")) || 0;
                            pagingAjaxData.BaseContentId = parseInt($.address.parameter("BaseContentId")) || 0;
                            //pagingAjaxData.CategoryId = parseInt(currentDirectory.data("categoryid")) || 0;
                            //pagingAjaxData.CategoryId = parseInt($.address.parameter("Categoryid")) || 0;
                            pagingAjaxData.PageSize = parseInt($.address.parameter("PageSize")) || 0;
                            pagingAjaxData.Author = decodeURIComponent(currentDirectory.data("author")).replace("+", " ");
                            pagingAjaxData.SearchInput = decodeURIComponent(currentDirectory.data("searchinput")).replace("+", " ");
                            //1102
                            if (pagingAjaxData.Author == "undefined") {
                                pagingAjaxData.Author = "";
							}
							if (pagingAjaxData.SearchInput == "undefined") {
								pagingAjaxData.SearchInput = "";
							}

                            pagingAjaxData.PageType = "article";

                            $('html, body').animate({
                                'scrollTop': $('.article-directory').offset().top
                            }, 500);
                        }

						//updateFilterClearButtons(currentDirectory, pagingAjaxData );

                    }

				    if (pushHistory) {
				        //wdot.AjaxStateHandler.UpdateHistory('/' + listType + '/' + pagingAjaxData.CurrentPage + '?' + $.param(pagingAjaxData));
				        wdot.AjaxStateHandler.UpdateHistory(listType + '?' + categoryQueryString + $.param(pagingAjaxData));
				    }

					var postUrl = listType === "mediaList" ? "/ContentPage/GetMediaForListingWidgetByCategory?IsUpdate=true&" : "/ContentPage/GetDirectoryListingWidget?IsUpdate=true&";

					var postCategoryQS = (categoryQueryString[0] != '&' ? '&' + categoryQueryString : categoryQueryString)
					
					
					if (_log) console.log('contentPages. postUrl: ', postUrl);
					if (_log) console.log('contentPages. pagingAjaxData: ', pagingAjaxData);
					if (_log) console.log('contentPages. $(.js-attributes-modal).length: ', $('.js-attributes-modal').length);
					// console.log('contentPages. before post js-accessibility-modal:', $('.js-accessibility-modal').length);
					// console.log('postCategoryQS: ', postCategoryQS);
					// console.log('categoryQueryString: ', categoryQueryString);

					//widget markup keeps getting re-added, including this modal. need to clear it out first
					if($('.js-attributes-modal').length > 0) $('.js-attributes-modal').remove();
					//widget markup keeps getting re-added, including this modal. need to clear it out first
					if($('.js-accessibility-modal').length > 0) $('.js-accessibility-modal').remove();

				    Wdot.Ajax.prototype.Post(postUrl + postCategoryQS, JSON.stringify(pagingAjaxData), function (d) {

						if (_log) console.log('ContentPages.js loadPage _> Wdot.Ajax.prototype.Post-ed');
                        //console.log('d: ', d);

				        $(".datesDisplay").remove();
				        currentDirectory.replaceWith(d);

				        if (pagingAjaxData.PageType === "place") {
				            //wdot.Directory.DirectoryPlaceSetUp();
						}
						
                        if (scrollTo) {
                            if (pagingAjaxData.PageType === "place" && currentPage !== 1) {
                                $('html, body').animate({
                                    scrollTop: $(".place-directory").offset().top
                                }, 500); 
                            }
                            else if (pagingAjaxData.PageType === "article" && currentPage !== 1) {
                                $('html, body').animate({
                                    'scrollTop': $('.article-directory').offset().top
                                }, 500); 
                            }
						}

                        $(".js-lazy").lazyload();
				        //to trigger lazy loading of currently visible images
                        $(window).scroll();
				        updateFilters();
				        wdot.City.loadCityAutocompleteForPublicSite($(".main"), ".cityFilterAutoComplete");

						if(tw.AttributesFilter) tw.AttributesFilter.init();
						if(tw.AccessibilityFilter) tw.AccessibilityFilter.init();
						if($('.js-filter-clear-buttons-loader').length > 0) updateFilterClearButtons(currentDirectory, pagingAjaxData, listType );
						
						setTimeout(() => {
							updateSubFilterDisplay();
						}, 2000);

                    }, null, 'application/json');
				},

		loadTripsPage = function (directoryContianer, resetPaging, data, pushHistory, currentPageNum) {
			wdot.Utils.ResetPlaceHolders();
			var pagingContainer = directoryContianer.find(".pagingContainer");
			var currentPage = currentPageNum || 1;
			var widgetId = directoryContianer.attr("data-unique-widget-id");
			var contentPageId = directoryContianer.attr("data-contentId");
			pagingContainer.attr('data-currentpage', !resetPaging ? currentPage : 1);
			var pagingAjaxData = {};
			pagingAjaxData.ContentPageId = contentPageId;
			pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
			pagingAjaxData.ContentPage_WidgetId = widgetId;
			var filters = directoryContianer.find(".filters");
			pagingAjaxData.City = filters.find("input[name=City]").val();
			pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();
			pagingAjaxData.RegionId = filters.find("select[name=RegionId]").val();

			if (pushHistory) {
				wdot.AjaxStateHandler.UpdateHistory('/Directory/' + pagingAjaxData.CurrentPage + '?' + $.param(pagingAjaxData));
			}

			Wdot.Ajax.prototype.Post("/ContentPage/GetTripsDirectoryList?IsUpdate=true", JSON.stringify(pagingAjaxData), function (d) {
				directoryContianer.replaceWith(d);
				var count = directoryContianer.attr("data-item-count");
				directoryContianer.parents("body").find(".directoryList .count").html(" (" + count + ")");
				$('ul.listings > li').hoverIntent(fancyDirectory);
				placeHolderEvents();
			}, null, 'application/json');
		},

        loadEventsPage = function (directoryContianer, resetPaging, newPageNumber, getDefaultValues, qs, pushHistory, listType, isUserClicked, scrollTo) {
            if (_log) console.group('loadEventsPage');
			if (_log) console.log('loadEventsPage().directoryContianer: ', directoryContianer);
			if (_log) console.log('loadEventsPage().resetPaging: ', resetPaging);
			if (_log) console.log('loadEventsPage().newPageNumber: ', newPageNumber);
			if (_log) console.log('loadEventsPage().getDefaultValues: ', getDefaultValues);
			if (_log) console.log('loadEventsPage().qs: ', qs);
			if (_log) console.log('loadEventsPage().pushHistory: ', pushHistory);
			if (_log) console.log('loadEventsPage().listType: ', listType);
			if (_log) console.log('loadEventsPage().isUserClicked: ', isUserClicked);
			if (_log) console.log('loadEventsPage().scrollTo: ', scrollTo);
			if (_log) console.groupEnd();

		    if (pushHistory == undefined) {
		        pushHistory = false;
            }

            if (scrollTo == 'undefined') {
                scrollTo = true;
            }

		    wdot.Utils.ResetPlaceHolders();
		    var pagingContainer = directoryContianer.find(".pagingContainer");
		    var currentPage = newPageNumber || 1;
		    var widgetId = directoryContianer.attr("data-unique-widget-id");
		    var contentPageId = directoryContianer.attr("data-contentId");
		    var baseContentId = directoryContianer.attr("data-basecontentid");
		    pagingContainer.attr('data-currentpage', !resetPaging ? currentPage : 1);
		    var pagingAjaxData = {};
		    pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
		    var categoryQueryString = '';

			//debugger;

		    if (qs == null || qs == undefined || qs == {} || qs == "") {

		        if (!getDefaultValues && listType === "directory" || listType === "eventList") {

					if (_log) console.log('loadEventsPage(). no qs, is directory');

		            pagingAjaxData.ContentPageId = contentPageId;
		            pagingAjaxData.ContentPage_WidgetId = widgetId;
		            pagingAjaxData.BaseContentId = baseContentId;
		            var filters = directoryContianer.find(".filters");
		            pagingAjaxData.Keyword = filters.find("input[name=Keyword]").val();
		            pagingAjaxData.StartDate = filters.find("input[name=StartDate]").val();
		            pagingAjaxData.EndDate = filters.find("input[name=EndDate]").val();
		            pagingAjaxData.CityId = filters.find("input[name=City]").attr("data-itemid");
					pagingAjaxData.County = filters.find("input[name=City]").attr("data-countyname");
		            pagingAjaxData.CountyId = filters.find("input[name=City]").attr("data-countyid");
		            pagingAjaxData.City = filters.find("input[name=City]").attr("data-cityname");
		            pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();
		            pagingAjaxData.CategoryId = filters.find("select[name=CategoryId]").val();
		            pagingAjaxData.RegionId = filters.find("select[name=RegionId]").val();
		            pagingAjaxData.PageType = "event";

		            //		            pagingAjaxData.PageSize = $("select[name=Results]").val();
		            //		            pagingAjaxData.UseUserLocation = $("input[name=UseUserLocation]").val();
		            var i = 0;
		            pagingAjaxData.AttributeFilters = [];

		            ko.utils.arrayForEach(directoryContianer.find(".directoryGrid__filters-attributes input[type=textbox], .directoryGrid__filters-attributes input[type=checkbox]:checked, .topAttributes input[type=checkbox]:checked"), function (item) {
		                pagingAjaxData["AttributeFilters[" + i.toString() + "].AttributeId"] = $(item).attr("data-attribute-id");
		                pagingAjaxData["AttributeFilters[" + i.toString() + "].Value"] = $(item).val();
		                pagingAjaxData["AttributeFilters[" + i.toString() + "].Name"] = $(item).attr("data-attribute-name");
		                pagingAjaxData["AttributeFilters[" + i.toString() + "].CoveoName"] = $(item).attr("data-attribute-coveo-name");
		                pagingAjaxData["AttributeFilters[" + i.toString() + "].ResponseMethodId"] = $(item).attr("data-attribute-response-method-id");
		                i++;
		            });
					//debugger;
					pagingAjaxData = checkAccessibilityFilters(pagingAjaxData, i);

		        } else if (listType === "articleList") {
		            pagingAjaxData.ContentPageId = contentPageId;
		            pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
		            pagingAjaxData.ContentPage_WidgetId = widgetId;
		            pagingAjaxData.BaseContentId = baseContentId;
		            pagingAjaxData.PageType = "article";
		        } else if (listType === "mediaList") {
		            pagingAjaxData.ContentPageId = contentPageId;
		            pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
		            pagingAjaxData.ContentPage_WidgetId = widgetId;
		            pagingAjaxData.BaseContentId = baseContentId;
		            pagingAjaxData.CategoryId = directoryContianer.data("categoryid");
		            pagingAjaxData.PageType = "media";
		        } else {
		            if ($("[name=DirectoryDefaultValue_" + widgetId + "]").val() != "" && $("[name=DirectoryDefaultValue_" + widgetId + "]").val() != undefined) {
		                pagingAjaxData = JSON.parse($("[name=DirectoryDefaultValue_" + widgetId + "]").val());
		            }

		            pagingAjaxData.ContentPage_WidgetId = widgetId;
		            pagingAjaxData.ContentPageId = contentPageId;
		            pagingAjaxData.BaseContentId = baseContentId;
		            pagingAjaxData.PageType = "event";
		        }

		        var queryString = window.location.search.replace("?", "").split("&");

				if (_log) console.log('contentPages.loadEventsPage pagingAjaxData: ', pagingAjaxData);
				if (_log) console.log('contentPages.loadEventsPage queryString: ', queryString);
				//debugger;

		        for (var t = 0; t < queryString.length; t++) {
		            if (queryString[t].split("=")[0] == "categoryId" || queryString[t].split("=")[0] == "category") {
		                categoryQueryString += "&" + queryString[t];
		            }
		        }
		    } else {

				if (_log) console.log('loadEventsPage(). found qs. set pagingAjaxData from $.address.parameter');

		        if ($.address.parameter("PageType") == 'event') {
                    pagingAjaxData.CurrentPage = parseInt($.address.parameter("CurrentPage")) || 0;
                    pagingAjaxData.ContentPageId = parseInt($.address.parameter("ContentPageId")) || 0;
                    pagingAjaxData.ContentPage_WidgetId = parseInt($.address.parameter("ContentPage_WidgetId")) || 0;
                    pagingAjaxData.BaseContentId = parseInt($.address.parameter("BaseContentId")) || 0;
		            pagingAjaxData.Keyword = $.address.parameter("Keyword");
		            pagingAjaxData.StartDate = decodeURIComponent($.address.parameter("StartDate"));
		            pagingAjaxData.EndDate = decodeURIComponent($.address.parameter("EndDate"));
                    pagingAjaxData.CityId = parseInt($.address.parameter("CityId")) || 0;
                    pagingAjaxData.CountyId = parseInt($.address.parameter("CountyId")) || 0;
		            pagingAjaxData.City = decodeURIComponent($.address.parameter("City")).replace("+", " ");
                    pagingAjaxData.MileRange = parseInt($.address.parameter("MileRange")) || 0;
                    pagingAjaxData.CategoryId = parseInt($.address.parameter("CategoryId")) || 0;
                    pagingAjaxData.RegionId = parseInt($.address.parameter("RegionId")) || 0;
		            pagingAjaxData.PageType = "event";

		            var loop = true,
						attributeCounter = 0;
		            pagingAjaxData.AttributeFilters = [];
		            for (var i = 0; loop; i++) {
		                if (typeof $.address.parameter(escape("AttributeFilters[" + i + "].AttributeId")) !== "undefined") {
		                    pagingAjaxData["AttributeFilters[" + i + "].AttributeId"] = $.address.parameter(escape("AttributeFilters[" + i + "].AttributeId"));
		                    pagingAjaxData["AttributeFilters[" + i + "].Value"] = $.address.parameter(escape("AttributeFilters[" + i + "].Value"));
		                    pagingAjaxData["AttributeFilters[" + i + "].Name"] = $.address.parameter(escape("AttributeFilters[" + i + "].Name"));
		                    pagingAjaxData["AttributeFilters[" + i + "].CoveoName"] = $.address.parameter(escape("AttributeFilters[" + i + "].CoveoName"));
		                    pagingAjaxData["AttributeFilters[" + i + "].ResponseMethodId"] = $.address.parameter(escape("AttributeFilters[" + i + "].ResponseMethodId"));
							attributeCounter ++;
		                }
		                else {
		                    loop = false;
		                }

                    }

                    $('html, body').animate({
                        scrollTop: $(".event-directory").offset().top
                    }, 500);

					if (_log) console.log('contentPages.loadEventsPage no qs & event pagingAjaxData: ', pagingAjaxData);
					//debugger;

					pagingAjaxData = checkAccessibilityFilters(pagingAjaxData, attributeCounter);

		        } else if ($.address.parameter("PageType") == 'article') {
                    //pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                    //pagingAjaxData.ContentPage_WidgetId = widgetId;
                    //pagingAjaxData.BaseContentId = baseContentId;
                    //pagingAjaxData.CurrentPage = $.address.parameter("CurrentPage");
                    pagingAjaxData.ContentPageId = parseInt($.address.parameter("ContentPageId")) || 0;
                    pagingAjaxData.ContentPage_WidgetId = parseInt($.address.parameter("ContentPage_WidgetId")) || 0;
                    pagingAjaxData.BaseContentId = parseInt($.address.parameter("BaseContentId")) || 0;
					pagingAjaxData.CategoryId = parseInt(directoryContianer.data("categoryid")) || 0;

					pagingAjaxData.SearchInput = decodeURIComponent(directoryContianer.data("searchinput")).replace("+", " ");
					if (pagingAjaxData.SearchInput == "undefined") {
						pagingAjaxData.SearchInput = "";
					}


					pagingAjaxData.SelectedCategoryName = directoryContianer.data("selectedCategoryName").replace(/\+/gi,' ');//jkjkjk right freakin here
                    pagingAjaxData.PageSize = parseInt($.address.parameter("PageSize")) || 0;
                    pagingAjaxData.Author = decodeURIComponent(directoryContianer.data("author")).replace("+", " ");

					debugger;

                    //1102
                    if (pagingAjaxData.Author == "undefined") {
                        pagingAjaxData.Author = "";
                    }

                    pagingAjaxData.PageType = "article";

                    $('html, body').animate({
                        'scrollTop': $('.article-directory').offset().top
                    }, 500);

		        } else if ($.address.parameter("PageType") == 'media') {
		            pagingAjaxData.CurrentPage = $.address.parameter("CurrentPage");
		            pagingAjaxData.ContentPageId = $.address.parameter("ContentPageId");
		            pagingAjaxData.ContentPage_WidgetId = $.address.parameter("ContentPage_WidgetId");
		            pagingAjaxData.BaseContentId = $.address.parameter("BaseContentId");
		            pagingAjaxData.CategoryId = directoryContianer.data("categoryid");
		            pagingAjaxData.PageType = "media";
		        }
		    }


		    if (pushHistory) {
				if (_log) console.log('contentPages.loadEventsPage pushHistory: ?' + categoryQueryString + $.param(pagingAjaxData));
				//debugger;
		        wdot.AjaxStateHandler.UpdateHistory('?' + categoryQueryString + $.param(pagingAjaxData));
		    }

		    var postUrl = listType === "mediaList" ? "/ContentPage/GetMediaForListingWidgetByCategory?IsUpdate=true&" : "/ContentPage/GetDirectoryListingWidget?IsUpdate=true&";


		    if ((listType === "mediaList") && ($.address.baseURL().toLowerCase().indexOf("/events") >= 0 )) {
		        postUrl = "/ContentPage/GetMediaByCategoryForEvents?IsUpdate=true&";
		    }

			if (_log) console.log('contentPages.loadEventsPage categoryQueryString: ', categoryQueryString);
			if (_log) console.log('contentPages.loadEventsPage postUrl: ', postUrl);
			if (_log) console.log('contentPages.loadEventsPage pagingAjaxData: ', pagingAjaxData);

			//widget markup keeps getting re-added, including this modal. need to clear it out first
			if($('.js-attributes-modal').length > 0) $('.js-attributes-modal').remove();
			//widget markup keeps getting re-added, including this modal. need to clear it out first
			if($('.js-accessibility-modal').length > 0) $('.js-accessibility-modal').remove();

			var fullPostUrl = postUrl + (categoryQueryString[0] != '&' ? '&' + categoryQueryString : categoryQueryString);
			if(fullPostUrl.slice(-1) == '&') {
				fullPostUrl = fullPostUrl.slice(0, -1);  
			}

		    Wdot.Ajax.prototype.Post(fullPostUrl, JSON.stringify(pagingAjaxData), function (d) {
		        $(".datesDisplay").remove();
		        directoryContianer.replaceWith(d);

		        $('ul.listings > li').hoverIntent(fancyDirectory);
		        placeHolderEvents();
		        if ($.address.parameter("PageType") === 'event') {
		            //wdot.Directory.DirectoryEventSetup();
		        }

		        if ($.address.parameter("PageType") === 'article') {
		            wdot.Directory.DirectoryArticleSetUp();
		        }

		        if ($.address.parameter("PageType") === 'media') {
		            wdot.Directory.DirectoryMediaSetUp();
		        }

                if (scrollTo) {
                    if (pagingAjaxData.PageType === "event" && currentPage !== 1) {
                        $('html, body').animate({
                            scrollTop: $(".event-directory").offset().top
                        }, 500);
                    }

                    if (pagingAjaxData.PageType === "article" && currentPage !== 1) {

                        $('html, body').animate({
                            'scrollTop': $('.article-directory').offset().top
                        }, 500);
                    }

                    if (pagingAjaxData.PageType === "media" && currentPage !== 1) {
                        $('html, body').animate({
                            'scrollTop': $('#mediaList').offset().top
                        }, 500);
                    }
                }
		        
                $(".js-lazy").lazyload();
		        //to trigger lazy loading of currently visible images
                $(window).scroll();
				if (_log) console.log('Wdot.Ajax.prototype.Post ed events ');
		        wdot.City.loadCityAutocompleteForPublicSite($(".main"), ".cityFilterAutoComplete");
				if (_log) console.log('Wdot.Ajax.prototype.Post ed events after city.loadCityAutocompleteForPublicSite');
		        updateFilters();

				if(tw.AttributesFilter) tw.AttributesFilter.init();
				if(tw.AccessibilityFilter) tw.AccessibilityFilter.init();
				if($('.js-filter-clear-buttons-loader').length > 0) updateFilterClearButtons(directoryContianer, pagingAjaxData, listType );

				updateSubFilterDisplay();
                
		    }, null, 'application/json');

		},

		debounceTimeout = null,
		debounceFilterInput = function (currentPageButton, resetPaging, fn) {
			window.clearInterval(debounceTimeout);
			debounceTimeout = setInterval(function () { fn(currentPageButton, resetPaging); window.clearInterval(debounceTimeout); }, 200);
		},

		loadMobileEventMap = function () {
			var latLongs = $(".mobileDirectoryList ul li[data-lat]").map(function () {
				return { lat: $(this).attr("data-lat"), lon: $(this).attr("data-long") };
			});

			var latitude = 44.832017349893526,
				longitude = -89.84687250000002;
			var center = new Microsoft.Maps.Location(latitude, longitude);

			if (map == null) {
				map = new Microsoft.Maps.Map($(".directoryMap")[0], {
				    enableSearchLogo: false,
				    showMapTypeSelector: false,
				    credentials: wdot.BingMaps.apiKey,
				    zoom: 11,
				    center: center,
				    disableKeyboardInput: true
				});
				wdot.BingMaps.bindMethodToPreventPinterestPinningMapTiles(map);
			} else {
				map.entities.clear();
			}

			var title = 1;
			ko.utils.arrayForEach(latLongs, function (item) {
				if (item.lat != 0 && item.lon != 0) {
					var pinOptions = { visible: true, draggable: false, text: '' + title };
					var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(item.lat, item.lon), pinOptions);
					map.entities.push(pin);
				}

				title++;
			});

			$(".directoryMap").bind('contextmenu', function (event) {
				return false;
			});
			$.debounce(250, $(".directoryMap").removeClass("hidden"));
			bestView();
		},

		clearEventFilters = function () {
			window.location.hash = 'directoryAnchor';
			if(_log)console.log('clearEventFilters() empty hash jumps to top. need to keep it on filter');
			$("input[name=Keyword],input[name=StartDate],input[name=EndDate],input[name=City]").val('');
			$("input[name=City]").attr('data-cityname', '');
			$("input[name=City]").attr('data-itemid', '');
			$("input[name=City]").attr('data-cityid', '');
			$("input[name=City]").attr('data-countyid', '');
			$("input[name=City]").attr('data-regionid', '');
		    $("select[name=RegionId]").val(0);
			$("select[name=MileRange]").val(0);
			$("select[name=CategoryId]").val(0);
			$('[data-attribute-name]').attr("checked", false);
			$('[data-attribute-name]').val('');
			$('[data-attribute-name]').val(0);

			//wdot.AjaxStateHandler.UpdateHistory('');

			//debugger;
			//$(".filters .update").click();
			if(_log)console.log('wdot/contentpages.js clearEventFilters()')
		},

		clearMobileFilters = function () {

			$("input[name=County],input[name=City]").val('');
			$("select[name=MileRange]").val(0);
			$("select[name=RegionId]").val(0);
			$('[data-attribute-name]').attr("checked", false);
			$('[data-attribute-name]').val('');
			$('[data-attribute-name]').val(0);
		},

		clearFilters = function () {
			//todo-jk: add something to clearout the query string as well. region and category are sticking
			window.location.hash = 'directoryAnchor';
			if(_log)console.log('clearFilters() empty hash jumps to top. need to keep it on filter');
			//debugger;

		    $("select[name=CategoryId]").val(0);
		    $("input[name=Keyword]").val('');
		    $("input[name=StartDate]").val('');
		    $("input[name=EndDate]").val('');
		    $("input[name=CityId]").val('');
		    $("input[name=City]").val('');
		    $("input[name=County]").val('');
		    $("select[name=MileRange]").val(0);
		    $("select[name=RegionId]").val(0);
		    $('[data-attribute-name]').attr("checked", false);
		    $('[data-attribute-name]').val('');
		    $('[data-attribute-name]').val(0);
		    $("input[name=City]").attr("data-cityname", "");
		    $("input[name=City]").attr("data-countyname", "");
		    $("input[name=City]").attr("data-regionid", "");
		    $("input[name=City]").attr("data-itemid", "");
		    $("input[name=County]").attr("data-countyname", "");
		    $("input[name=CountyId]").attr("data-countyid", "");
		    $(".filter-buttons .update").click();
			if(_log)console.log('wdot/contentpages.js clearFilters()')
		},

		clearIndustryTravelGreenFilters = function () {

			$("select[name=SortBy],select[name=CategoryId]").val(0);
			$(".directoryListContainer .update").click();
		},

		clearCitiesAndRegionsFilters = function () {

			var isCityTab = $(".ui-tabs-active").children("a").attr("href").indexOf("cities") >= 0;
			var directory = isCityTab ? $("#cities") : $("#roadTrips");
			directory.find("input[name=County],input[name=City]").val('');
			directory.find("select[name=MileRange]").val(0);
			directory.find("select[name=RegionId]").val(0);
			directory.find(".update").click();
		},

		clearTripFilters = function () {

			$("input[name=City]").val('');
			$("select[name=MileRange]").val(0);
			$("select[name=RegionId]").val(0);
			$(".directoryListContainer .update").click();
		},

		placeHolderEvents = function () {   // If the broser doesn't support placeholders, replicate the functionality with js
			if (!Modernizr.input.placeholder) {
				$(".filters").find('[placeholder]')
					.focus(function () {
						var input = $(this);

						if (input.val() == input.attr('placeholder')) {
							input.val('');
							input.removeClass('placeholder');
						}
					})
					.blur(function () {
						var input = $(this);

						if (input.val() == '' || input.val() == input.attr('placeholder')) {
							input.addClass('placeholder');
							input.val(input.attr('placeholder'));
						}
					})
					.blur();

				$(".filters").find(".update").click(function () {
					$(this).find('[placeholder]').each(function () {
						var input = $(this);

						if (input.val() == input.attr('placeholder')) {
							input.val('');
						}
					});
				});
			}
		},

		loadContentPagesAutocomplete = function(selector, selectorFind) {
			if(_log)console.log('loadContentPagesAutocomplete:', selector, selectorFind);
	            var cache = {},
	                lastXhr = '',
	                itemSelected = false;
	            $(selector).find(selectorFind).autocomplete({
	                source: function(request, response) {
	                    itemSelected = false;
	                    response([{ Id: "AJAX", Name: '' }]);
	                    wdot.Ajax.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
	                    var term = request.term;

	                    if (term in cache) {
	                        wdot.Ajax.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
	                        response(cache[term]);

	                        return;
	                    }

	                    lastXhr = wdot.Ajax.Get("/admin/contentpage/autocompletecontentpages", request, function(data, status, xhr) {
	                        cache[term] = data;

	                        if (xhr === lastXhr) {
	                            response(data);
	                        }
	                    });
	                },

	                focus: function(event, ui) {
	                    $(selector).find(selectorFind).val(ui.item.Name);

	                    return false;
	                },

	                select: function(event, ui) {
	                    itemSelected = true;
	                    $(selector).find(selectorFind).val(ui.item.DocumentName);
	                    $(selector).find(selectorFind).attr("data-itemid", ui.item.Id);
	                    $(selector).find(selectorFind).attr("data-listItemData", ui.item.Data);

	                    return false;
	                },

	                close: function(event) {
	                    Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
	                },

				minLength: 1
			}).data("ui-autocomplete")._renderItem = function (ul, item) {
				return $("<li></li>")
					.data("ui-autocomplete-item", item)
					.append("<a data-itemid='" + item.Id + "' data-listItemData='" + item.Data + "'>" + item.DocumentName + "</a>")
					.appendTo(ul);
			};
		},

	    loadDealsPage = function (directoryContianer, resetPaging, newPageNumber, getDefaultValues, qs, pushHistory, listType) {
			if (_log) console.group('loadDealsPage()');
			if (_log) console.log('loadDealsPage().listType: ', listType);
			if (_log) console.log('loadDealsPage().directoryContianer: ', directoryContianer);
			if (_log) console.log('loadDealsPage().directoryContianer.data: ', directoryContianer.data);
			if (_log) console.log('loadDealsPage().directoryContianer.data.author: ', directoryContianer.data('author'));
			if (_log) console.log('loadDealsPage().directoryContianer.data.searchinput: ', directoryContianer.data('searchinput'));
			if (_log) console.log('loadDealsPage().resetPaging: ', resetPaging);
			if (_log) console.log('loadDealsPage().newPageNumber: ', newPageNumber);
			if (_log) console.log('loadDealsPage().getDefaultValues: ', getDefaultValues);
			if (_log) console.log('loadDealsPage().qs: ', qs);
			if (_log) console.log('loadDealsPage().pushHistory: ', pushHistory);
			if (_log) console.log('loadDealsPage().listType: ', listType);
			if (_log) console.groupEnd();


			if (pushHistory == undefined) {
			    pushHistory = false;
			}

			//if(_log)console.log('contentpages.loadDealsPage');

			wdot.Utils.ResetPlaceHolders();
			var pagingContainer = directoryContianer.find(".pagingContainer");
			var currentPage = newPageNumber || 1;
			var widgetId = directoryContianer.attr("data-unique-widget-id");
			var contentPageId = directoryContianer.attr("data-contentId");
			var baseContentId = directoryContianer.attr("data-basecontentid");
			pagingContainer.attr('data-currentpage', !resetPaging ? currentPage : 1);
			var pagingAjaxData = {};
			pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
			var categoryQueryString = '';

			if (qs == null || qs == undefined || qs == {} || qs == "") {
				if (_log) console.log('loadDealsPage().qs is empty. start new pagingAjaxData');

			    if (!getDefaultValues && listType === "directory" || listType === "eventList") {
			        pagingAjaxData.ContentPageId = contentPageId;
			        pagingAjaxData.ContentPage_WidgetId = widgetId;
			        pagingAjaxData.BaseContentId = baseContentId;
			        var filters = directoryContianer.find(".filters");
			        pagingAjaxData.Keyword = filters.find("input[name=Keyword]").val();
			        pagingAjaxData.StartDate = filters.find("input[name=StartDate]").val();
			        pagingAjaxData.EndDate = filters.find("input[name=EndDate]").val();
			        pagingAjaxData.CityId = filters.find("input[name=City]").attr("data-itemid");
			        pagingAjaxData.CountyId = filters.find("input[name=City]").attr("data-countyid");
			        pagingAjaxData.City = filters.find("input[name=City]").attr("data-cityname");
                    pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();
                    pagingAjaxData.CategoryId = filters.find("select[name=CategoryId]").val();
                    pagingAjaxData.RegionId = filters.find("select[name=RegionId]").val();
			        pagingAjaxData.PageType = "deal";
			        var i = 0;
			        pagingAjaxData.AttributeFilters = [];

			    }
			    var queryString = window.location.search.replace("?", "").split("&");

			    for (var t = 0; t < queryString.length; t++) {
			        if (queryString[t].split("=")[0] == "categoryId" || queryString[t].split("=")[0] == "category") {
			            categoryQueryString += "&" + queryString[t];
			        }
			    }
			} else {

			    if ($.address.parameter("PageType") == 'deal') {
			        pagingAjaxData.CurrentPage = $.address.parameter("CurrentPage");
			        pagingAjaxData.ContentPageId = $.address.parameter("ContentPageId");
			        pagingAjaxData.ContentPage_WidgetId = $.address.parameter("ContentPage_WidgetId");
			        pagingAjaxData.BaseContentId = $.address.parameter("BaseContentId");
			        pagingAjaxData.Keyword = $.address.parameter("Keyword");
			        pagingAjaxData.StartDate = decodeURIComponent($.address.parameter("StartDate"));
			        pagingAjaxData.EndDate = decodeURIComponent($.address.parameter("EndDate"));
			        pagingAjaxData.CityId = $.address.parameter("CityId");
			        pagingAjaxData.City = decodeURIComponent($.address.parameter("City")).replace("+", " ");
                    pagingAjaxData.MileRange = $.address.parameter("MileRange");
                    pagingAjaxData.CategoryId = $.address.parameter("CategoryId");
                    pagingAjaxData.RegionId = $.address.parameter("RegionId");
			        pagingAjaxData.PageType = "deal";

			        var loop = true;

			    } else if ($.address.parameter("PageType") == 'article') {
			        pagingAjaxData.CurrentPage = $.address.parameter("CurrentPage");
			        pagingAjaxData.ContentPageId = $.address.parameter("ContentPageId");
			        pagingAjaxData.ContentPage_WidgetId = $.address.parameter("ContentPage_WidgetId");
			        pagingAjaxData.BaseContentId = $.address.parameter("BaseContentId");
			        pagingAjaxData.PageType = "article";
			    } else if ($.address.parameter("PageType") == 'media') {
			        pagingAjaxData.CurrentPage = $.address.parameter("CurrentPage");
			        pagingAjaxData.ContentPageId = $.address.parameter("ContentPageId");
			        pagingAjaxData.ContentPage_WidgetId = $.address.parameter("ContentPage_WidgetId");
			        pagingAjaxData.BaseContentId = $.address.parameter("BaseContentId");
			        pagingAjaxData.PageType = "media";
			    }
			}



			if (pushHistory) {
			    wdot.AjaxStateHandler.UpdateHistory('?' + categoryQueryString + $.param(pagingAjaxData));
			}

			var postUrl = listType === "mediaList" ? "/ContentPage/GetMediaForListingWidgetByCategory?IsUpdate=true&" : "/ContentPage/GetDirectoryListingWidget?IsUpdate=true&";

			if (_log) console.log('contentPages.loadDealsPage postUrl: ', postUrl);
			if (_log) console.log('contentPages.loadDealsPage pagingAjaxData: ', pagingAjaxData);

			Wdot.Ajax.prototype.Post(postUrl + (categoryQueryString[0] !== '&' ? '&' + categoryQueryString : categoryQueryString), JSON.stringify(pagingAjaxData), function (d) {
				console.log('contentpages.loadDealsPage.post done');
			    $(".datesDisplay").remove();
			    directoryContianer.replaceWith(d);
			    $('ul.listings > li').hoverIntent(fancyDirectory);
			    //wdot.Directory.DirectoryDealSetup();//shouldn't need to re init each time
				wdot.City.loadCityAutocompleteForPublicSite($(".main"), ".cityFilterAutoComplete");
			    wdot.Deals.setDateTimeCountDownTimer();

			    $('html, body').animate({
			        scrollTop: $(".deal-directory").offset().top
			    }, 500);

				$('.js-lazy').lazyload();
				//if(_log)console.log('contentpages js-lazy', $('.js-lazy').length);

				if($('.js-filter-clear-buttons-loader').length > 0) updateFilterClearButtons(directoryContianer, pagingAjaxData, listType );

			}, null, 'application/json');
		},



		loadCitiesAndRegionsPage = function (directoryContianer, resetPaging, newPageNumber, getDefaultValues, qs, pushHistory, listType) {
			if (pushHistory == undefined) {
				pushHistory = false;
			}
			wdot.Utils.ResetPlaceHolders();
			var pagingContainer = directoryContianer.find(".pagingContainer");
			var currentPage = newPageNumber || 1;
			var widgetId = directoryContianer.attr("data-unique-widget-id");
			var contentPageId = directoryContianer.attr("data-contentId");
			var baseContentId = directoryContianer.attr("data-basecontentid");
			pagingContainer.attr('data-currentpage', !resetPaging ? currentPage : 1);
			var pagingAjaxData = {};
			pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
			var categoryQueryString = '';

			pagingAjaxData.ContentPageId = contentPageId;
			pagingAjaxData.ContentPage_WidgetId = widgetId;
			pagingAjaxData.BaseContentId = baseContentId;
			var filters = directoryContianer.find(".filters");
			//pagingAjaxData.Keyword = filters.find("input[name=Keyword]").val();
			//pagingAjaxData.StartDate = filters.find("input[name=StartDate]").val();
			//pagingAjaxData.EndDate = filters.find("input[name=EndDate]").val();
			//pagingAjaxData.CityId = filters.find("input[name=City]").attr("data-itemid");
			//pagingAjaxData.CountyId = filters.find("input[name=City]").attr("data-countyid");
			//pagingAjaxData.City = filters.find("input[name=City]").attr("data-cityname");
			//pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();
			//pagingAjaxData.CategoryId = filters.find("select[name=CategoryId]").val();
			pagingAjaxData.RegionId = filters.find("select[name=RegionId]").val();
			pagingAjaxData.PageType = "directory";
			var i = 0;
			pagingAjaxData.AttributeFilters = [];

			//var queryString = window.location.search.replace("?", "").split("&");

			//for (var t = 0; t < queryString.length; t++) {
			//	if (queryString[t].split("=")[0] == "categoryId" || queryString[t].split("=")[0] == "category") {
			//		categoryQueryString += "&" + queryString[t];
			//	}
			//}

			if (pushHistory) {
				wdot.AjaxStateHandler.UpdateHistory('?' + categoryQueryString + $.param(pagingAjaxData));
			}

			var postUrl = "/ContentPage/GetDirectoryListingWidget?IsUpdate=true&";

			Wdot.Ajax.prototype.Post(postUrl + (categoryQueryString[0] !== '&' ? '&' + categoryQueryString : categoryQueryString), JSON.stringify(pagingAjaxData), function (d) {
				//$(".datesDisplay").remove();
				directoryContianer.replaceWith(d);
				$('ul.listings > li').hoverIntent(fancyDirectory);
				//wdot.Directory.DirectoryDealSetup();
				//wdot.Deals.setDateTimeCountDownTimer();

				//$('html, body').animate({
				//	scrollTop: $(".deal-directory").offset().top
				//}, 500);
				if(_log)console.log('contentpages done with post update');
				//if(_log)console.log('d:', d);
				$('.js-lazy').lazyload();
				//if(_log)console.log('contentpages js-lazy', $('.js-lazy').length);
				//there's a bug with lazyload that you need to trigger some scrolling to get it to kick in with newly added objects
				setTimeout(() => {
					var scrollTop = $(document).scrollTop();
					//if(_log)console.log('contentpages scrollTop: ', scrollTop);    
					window.scrollTo(0, scrollTop + 1);
				}, 100);

			}, null, 'application/json');
		};

        // Adding google analytics for button snippets that get loaded via admin cms for custom typeform forms
        $(document).ready(function () {
            $('.typeform-share').on('click', function () {
                ga("send", "event", "Quizzes", "Take the Quiz Button", $(this).html());
            });
        });

		return {
			LoadMap: loadMap,
			MovePin: movePin,
			BestView: bestView,
			LoadPage: loadPage,
			LoadMobileEventMap: loadMobileEventMap,
			LoadEventsPage: loadEventsPage,
			DebounceFilterInput: debounceFilterInput,
			ClearEventFilters: clearEventFilters,
			ClearMobileFilters: clearMobileFilters,
			ClearFilters: clearFilters,
			ClearCitiesAndRegionsFilters: clearCitiesAndRegionsFilters,
			ClearTripFilters: clearTripFilters,
			LoadTripsPage: loadTripsPage,
			LoadIndustryTravelGreenPage: loadIndustryTravelGreenPage,
			PlaceHolderEvents: placeHolderEvents,
			LoadCityPageMap: loadCityPageMap,
			CityPageListingTypes: cityPageListingTypes,
			ClearIndustryTravelGreenFilters: clearIndustryTravelGreenFilters,
			LoadContentPagesAutocomplete: loadContentPagesAutocomplete,
			LoadDealsPage: loadDealsPage,
			LoadCitiesAndRegionsPage: loadCitiesAndRegionsPage,
			updateFilters: updateFilters
		};
	} ();

	return {
		DirectoryListing: directoryListing
	};
} ();