var tw = tw || {};
tw.SlickSliders = function () {
    var _log = false;
    if(_log) console.log('tw.SlickSliders');

    var initializeArticles = function () {
        if(_log) console.log('SlickSliders.initializeArticles()');
        var $slickElement = $('.js-articles-slick');

        $slickElement.on('init', function (event, slick) {
            setTimeout(function() {
                $slickElement.find('a').removeClass('opacity-0');
            }, 300);
        });
          
        $slickElement.slick({
            infinite: false,
            slidesToShow: 1.1,
            slidesToScroll: 1,
            appendArrows: '.js-slick-nav-articles',
            prevArrow: '<a href="#" role="button" title="Navigate to Previous Slide" class="slick-prev mr-2 js-analytics-event text-decoration-none bg-quaternary" data-event-category="Article Slider" data-event-action="Previous Arrow Click" data-event-label="Article Previous Arrow"><i class="fas fa-long-arrow-alt-left text-light text-32"></i></a>',
            nextArrow: '<a href="#" role="button" title="Navigate to Next Slide" class="slick-next js-analytics-event ml-auto text-decoration-none bg-quaternary" data-event-category="Article Slider" data-event-action="Next Arrow Click" data-event-label="Article Next Arrow"><i class="fas fa-long-arrow-alt-right text-light text-32"></i></a>',
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 9000,
                    settings: {
                        slidesToShow: 5.25,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 4.25,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4.25,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3.25,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2.25,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1.1,
                        slidesToScroll: 1,
                    }
                }
            ]      
        });
    }




    var initializeCategories = function () {
        if(_log) console.log('SlickSliders.initializeCategories()');
        
        var $slickElement = $('.js-categories-slick');
        $slickElement.slick({
            infinite: false,
            slidesToShow: 1.1,
            slidesToScroll: 1,
            appendArrows: '.js-slick-nav-categories',
            prevArrow: '<a href="#" role="button" title="Navigate to Previous Slide" class="slick-prev mr-2 js-analytics-event text-decoration-none bg-quaternary" data-event-category="Category Slider" data-event-action="Previous Arrow Click" data-event-label="Category Previous Arrow"><i class="fas fa-long-arrow-alt-left text-light text-32"></i></a>',
            nextArrow: '<a href="#" role="button" title="Navigate to Next Slide" class="slick-next js-analytics-event ml-auto text-decoration-none bg-quaternary" data-event-category="Category Slider" data-event-action="Next Arrow Click" data-event-label="Category Next Arrow"><i class="fas fa-long-arrow-alt-right text-light text-32"></i></a>',
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 9000,
                    settings: {
                        slidesToShow: 6.1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 5.1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4.1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 2.1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1.1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }



    var initializeListingPage = function () {
        if(_log) console.log('SlickSliders.initializeListingPage()');
        var $slickElement = $('.js-listing-page-slick');
        $slickElement.slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendArrows: '.js-slick-nav-listing-page',
            prevArrow: '<a href="#" role="button" title="Navigate to Previous Slide" class="slick-prev mr-2 js-analytics-event text-decoration-none bg-light shadow" data-event-action="Episode Slider" data-event-label="Left arrow click"><i class="fas fa-long-arrow-alt-left text-quaternary text-24"></i></a>',
            nextArrow: '<a href="#" role="button" title="Navigate to Next Slide" class="slick-next js-analytics-event ml-auto text-decoration-none bg-light shadow" data-event-action="Episode Slider" data-event-label="Right arrow click"><i class="fas fa-long-arrow-alt-right text-quaternary text-24"></i></a>',
            variableWidth: false,
            dots: true,
            dotsClass: 'slick-nav-dots'
        });
        if(_log) console.log('$(.js-listing-page-slick .slick-nav-dots li).length:', $('.js-listing-page-slick .slick-nav-dots li').length);
        if($('.js-listing-page-slick .slick-nav-dots li').length < 2) {
            $('.js-listing-page-slick .slick-nav-dots').addClass('hidden');
        }
    }



    var initializeMedia = function () {
        if(_log) console.log('SlickSliders.initializeMedia()');

        var $slickElement = $('.js-media-slick');

        $slickElement.each(function( index ) {
            var sliderGuid = $(this).data('slider-guid');
            if(_log) console.log("js-media-slick:::::", sliderGuid );

            $(this).on('init', function (event, slick) {
                //console.log('$(this)', $(this));
                setTimeout(function() {
                    const slides = document.querySelector('[data-slider-guid="' + sliderGuid + '"]').querySelectorAll('.slick-slide');
                    //console.log('media.slides:', slides);
                    slides.forEach(slide => {
                        slide.classList.remove('opacity-0');
                    });
                }, 1000);
            });

            $(this).slick({
                infinite: false,
                slidesToShow: 1.1,
                slidesToScroll: 1,
                appendArrows: '.js-slick-nav-media-' + sliderGuid,
                prevArrow: '<a href="#" role="button" title="Navigate to Previous Slide" class="slick-prev mr-2 js-analytics-event text-decoration-none bg-quaternary" data-event-category="Article Slider" data-event-action="Previous Arrow Click" data-event-label="Article Previous Arrow"><i class="fas fa-long-arrow-alt-left text-light text-32"></i></a>',
                nextArrow: '<a href="#" role="button" title="Navigate to Next Slide" class="slick-next js-analytics-event ml-auto text-decoration-none bg-quaternary" data-event-category="Article Slider" data-event-action="Next Arrow Click" data-event-label="Article Next Arrow"><i class="fas fa-long-arrow-alt-right text-light text-32"></i></a>',
                variableWidth: false,
                responsive: [
                    {
                        breakpoint: 9000,
                        settings: {
                            slidesToShow: 5.25,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 1800,
                        settings: {
                            slidesToShow: 4.25,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4.25,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3.25,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 2.25,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1.1,
                            slidesToScroll: 1,
                        }
                    }
                ]      
            });

        })

    }




    var initializeMediaGallerySBS = function () {
        if(_log) console.log('SlickSliders.initializeMediaGallerySBS()');
        var $slickElement = $('.js-media-sbs-slick');

        $slickElement.each(function( index ) {
            var sliderGuid = $(this).data('sbs-guid');
            if(_log) console.log("js-media-sbs-slick: ", sliderGuid );

            $(this).slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                appendArrows: '.js-slick-nav-media-sbs-' + sliderGuid,
                prevArrow: '<a href="#" role="button" title="Navigate to Previous Slide" class="slick-prev mr-2 js-analytics-event text-decoration-none bg-light" data-event-action="Episode Slider" data-event-label="Left arrow click"><i class="fas fa-long-arrow-alt-left text-quaternary text-24"></i></a>',
                nextArrow: '<a href="#" role="button" title="Navigate to Next Slide" class="slick-next js-analytics-event ml-auto text-decoration-none bg-light" data-event-action="Episode Slider" data-event-label="Right arrow click"><i class="fas fa-long-arrow-alt-right text-quaternary text-24"></i></a>',
                variableWidth: false
            });

        })
    }

    

    return {
        initializeArticles: initializeArticles,
        initializeCategories: initializeCategories,
        initializeListingPage: initializeListingPage,
        initializeMedia: initializeMedia,
        initializeMediaGallerySBS: initializeMediaGallerySBS
    }

}();
